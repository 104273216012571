.is-loading > .loading-mask {

	display: flex;
}

.loading-mask {

	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: var(--black-color);
	opacity: 0.5;
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 5;

	&:after {

		content: '';
		border-radius: 50%;
		background-color: var(--medium-red-color);
		width: 40px;
		height: 40px;
		animation: pulse 1.5s infinite;
		position: absolute;
		left: calc(50% - 20px);
		top: calc(50% - 20px);

		@keyframes pulse {

			0% {

				transform: scale(1);
				opacity: 0.5;
			}

			50% {

				transform: scale(1.3);
				opacity: 1
			}

			100% {

				transform: scale(1);
				opacity: 0.5;
			}
		}
	}
}