header {

	position: sticky;
	z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
	background-color: var(--bg-light);

	.header-container {

		width: 46 * $columnSizeDesktop;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		position: relative;
		border-bottom: 1px solid var(--gray-color);

		.bg-close {

			position: fixed;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--bg-dark);
			z-index: 9;
			opacity: 0;
			transition: opacity $timeToAnimate;
		}

		.bg-header {

			display: none;
			position: absolute;
			top: 0;
			left: -2 * $columnSizeMobile;
			width: calc(100% + 4 * #{$columnSizeMobile});
			height: 100%;
			z-index: 11;
			background-color: var(--bg-light);
		}

		&.show {

			.btn-toggle-menu {

				svg {

					&:first-child {

						opacity: 0;
					}

					&:last-child {

						opacity: 1;
					}
				}
			}
		}

		.btn-toggle-menu {

			z-index: 12;
			display: none;
			margin-right: auto;
			padding: 0;
			border: none;
			background: none;
			width: 19px;
			height: 19px;
			position: relative;

			svg {

				position: absolute;
				top: 0;
				left: 0;
				width: 19px;
				height: 19px;
				transition: opacity $timeToAnimate;

				&:last-child {

					opacity: 0;
				}
			}
		}

		.logo {

			z-index: 12;

			a {

				display: block;
				line-height: 0;
				width: 230px;
				height: 41px;

				.image-logo {

					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.menu-options {

			background-color: var(--bg-light);
			flex: 1;
			z-index: 10;
			display: flex;
			align-items: center;

			.unit-id {

				position: absolute;
				top: 100%;
				left: 0;
				background-color: var(--medium-red-color);
				padding: 14px 37px;
				border-radius: 0px 0px 30px 30px;
				font-family: Montserrat;
				font-size: 15px;
				font-style: normal;
				font-weight: 600;
				line-height: 18px;
				letter-spacing: 0em;
				color: var(--text-light);
			}
			
			.menu-links {

				flex: 1;
				padding-left: 2 * $columnSizeDesktop;

				ul {

					display: flex;
					align-items: center;

					li {

						margin-right: $columnSizeDesktop;

						a {

							position: relative;
							display: block;
							padding-top: 36px;
							padding-bottom: 32px;
							border-bottom: 4px solid transparent;
							font-size: 15px;
							line-height: 18.29px;
							font-weight: 500;
							transition: border-color $timeToColor,
										color $timeToColor;

							&:active {

								border-color: var(--medium-red-color);
								color: var(--medium-red-color);
							}

							&.active {

								border-color: var(--medium-red-color);
							}
						}

						.dropdown-menu {

							position: relative;
							height: 100%;

							.dropdown-menu-title {

								height: 100%;
								cursor: pointer;
								font-family: "Montserrat", sans-serif;
								padding-top: 36px;
								padding-bottom: 32px;
								border-bottom: 4px solid transparent;
								font-size: 15px;
								line-height: 18.29px;
								font-weight: 500;
								transition: border-color $timeToColor,
											color $timeToColor;
								display: flex;
								align-items: center;

								svg {

									margin-left: 10px;
									width: 10px;
									height: auto;
									transition: transform $timeToAnimate;

									path {

										transition: fill $timeToColor;
										stroke: var(--text-dark);
									}
								}


								&.show,
								&:active {

									border-color: var(--medium-red-color);
									color: var(--medium-red-color);

									svg {

										path {

											stroke: var(--medium-red-color);
										}
									}
								}

								&.show {

									svg {

										transform: rotate(-180deg);
									}
								}
							}

							.dropdown-sub-menu {

								position: absolute;
								overflow: hidden;
								top: 100%;
								left: 0;
								width: 210px;
								max-height: 0;
								opacity: 0;
								transition: max-height $timeToAnimate, opacity $timeToAnimate;

								ul {

									border: 1px solid var(--gray-color);
									align-items: flex-start;
									flex-direction: column;
									background-color: var(--white-color);

									li {

										margin-right: 0;
										width: 100%;

										a {

											width: 100%;
											padding: 15px 10px;
											border: none;
											transition: color $timeToColor, background-color $timeToColor;

											&:active {

												background-color: #f9f9f9;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.button-and-language {

				display: flex;
				align-items: center;

				.language-options {

					margin-left: 20px;
					display: flex;
					align-items: center;
					position: relative;
					cursor: pointer;

					.flag {

						line-height: 0;
						margin-right: 7px;

						svg {

							width: 19px;
							height: auto;
						}
					}

					.chevron {

						line-height: 0;
						
						svg {

							transition: transform $timeToAnimate;
							width: 10px;
							height: auto;
						}
					}

					.other-languages {

						cursor: initial;
						position: absolute;
						height: 0px;
						width: 57px;
						top: calc(100% + 5px);
						left: 50%;
						transform: translate(-50%);
						opacity: 0;
						transition: opacity $timeToAnimate,
						 			height $timeToAnimate;
						padding-left: 10px;
						background-color: var(--bg-light);
						box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
						border-radius: 0px 0px 10px 0px;
						display: flex;
						align-items: center;
						overflow: hidden;

						a {

							display: block;
							line-height: 0;

							&.current {

								display: none;
							}
						}
					}

					&.show {

						.chevron {

							svg {

								transform: rotate(-180deg);
							}
						}

						.other-languages {
						
							height: 52px;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.header-container {

			.menu-options {

				.menu-links {

					ul {

						li {

							a {
								
								&:hover {
								
									border-color: var(--medium-red-color);
									color: var(--medium-red-color);
								}
							}

							.dropdown-menu {

								.dropdown-menu-title {
								
									&:hover {
									
										border-color: var(--medium-red-color);
										color: var(--medium-red-color);

										svg {

											path {

												stroke: var(--medium-red-color);
											}
										}
									}
								}

								.dropdown-sub-menu {

									ul {

										li {

											a {

												&:hover {

													background-color: #f9f9f9;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: 1400px) {

		.header-container {

			.menu-options {

				.menu-links {

					ul {

						li {

							flex: 1;
							text-align: center;
							align-self: stretch;
							margin-right: 1.5vw;

							a {

								display: flex;
								align-items: center;
								justify-content: center;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: 1300px) {

		.header-container {

			.menu-options {

				.button-and-language {

					.btn {

						display: none;
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		.header-container {

			width: 21 * $columnSizeMobile;
			padding-top: 17px;
			padding-bottom: 13px;

			.bg-header {

				display: block;
			}

			.btn-toggle-menu {

				display: block;
			}

			.logo {

				a {

					width: 221px;
					height: 39.43;
				}
			}

			.menu-options {

				padding-top: 81.5px;
				position: fixed;
				bottom: calc(100% + 10px);
				left: 0;
				max-height: 100%;
				width: 100%;
				overflow-y: auto;
				transition: transform $timeToAnimate;
				flex-direction: column;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

				.unit-id {

					position: relative;
					top: unset;
					left: unset;
					width: 100%;
					background-color: transparent;
					color: var(--medium-red-color);
					font-size: 14px;
					font-weight: 500;
					line-height: 17px;
					padding-bottom: 31px;
					padding-left: 2 * $columnSizeMobile;
					padding-right: 2 * $columnSizeMobile;
				}

				.menu-links {

					padding-bottom: 3px;
					width: 23 * $columnSizeMobile;
					padding-left: $columnSizeMobile;
					padding-right: $columnSizeMobile;
					padding-top: 31px;

					ul {

						width: 100%;
						flex-direction: column;

						li {

							width: 100%;
							margin-bottom: 27px;
							margin-right: 0px;
							
							a {

								border-bottom: none;
								padding-top: 0px;
								padding-bottom: 0px;
								justify-content: flex-start;

								.link-text {

									position: relative;
									top: unset;
									left: unset;
									transform: translate(0, 0);
								}
							}

							.dropdown-menu {

								.dropdown-menu-title {

									padding-top: 0px;
									padding-bottom: 0px;
									justify-content: flex-start;
									border-bottom: none;
								}

								.dropdown-sub-menu {

									width: auto;
									position: unset;
							
									ul {

										padding-top: 10px;
										padding-bottom: 10px;
										border: none;

										li {
											
											margin-bottom: 0;
											padding-top: 7px;
											padding-bottom: 7px;

											a {

												padding: 0;
											}
										}
									}
								}
							}
						}
					}

					&:not(:first-child) {

						border-top: 1px solid var(--medium-red-color);
					}

					&:not(:last-child) {

						border-bottom: 1px solid var(--medium-red-color);
					}
				}

				.button-and-language {

					width: 100%;

					.btn {

						display: none;
					}

					.language-options {

						width: 100%;
						margin-left: 0px;
						cursor: normal;

					    & > .flag {

					    	display: none;
						}

						.chevron {
							
							display: none;
						}

						.other-languages {

							width: 100%;
							position: relative;
							left: unset;
							top: unset;
							transform: none;
							height: auto;
							opacity: 1;
							box-shadow: none;
							padding: 31px (2 * $columnSizeMobile);

							a {

								&:not(:last-child) {

									margin-right: 18px;
								}

								&.current {

									display: block;
								}

								.flag {

									margin-right: 0px;
								}

								svg {

									width: 38px;
									height: 29px;
								}
							}
						}

						&.show {

							.other-languages {
							
								height: auto;
							}
						}
					}
				}
			}

			&.show {	

				.bg-close {

					opacity: 0.6;
					transform: translateY(100%);
				}

				.menu-options {

					transform: translateY(calc(100% + 10px));
				}
			}
		}
	}
}