.results-units {

	padding-top: 104px;
	padding-bottom: 177px;
	padding-left: $columnSizeDesktop;
	padding-right: $columnSizeDesktop;

	.icon-hidden {

		display: none;
	}

	.grid {

		display: flex;
		flex-wrap: wrap;

		.item {

			margin-bottom: 60px;
			width: calc(33.333% - 2 * #{$columnSizeDesktop});
			margin-left: $columnSizeDesktop;
			margin-right: $columnSizeDesktop;
			display: flex;
			flex-direction: column;

			img {

				width: 100%;
				object-fit: cover;
				object-position: center;
				height: 6.3 * $columnSizeDesktop;
				border-radius: 10px 10px 0px 0px;
			}

			.body {

				background-color: var(--bg-medium);
				padding: 52px $columnSizeDesktop 58px (2 * $columnSizeDesktop);
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.location {

					margin-bottom: 16px;
					color: var(--medium-red-color);
					font-size: 13px;
					font-style: normal;
					font-weight: 700;
					line-height: 16px;
					text-transform: uppercase;
					letter-spacing: 0.1em;
				}

				.title {

					display: block;
					font-size: 25px;
					font-style: normal;
					font-weight: 600;
					line-height: 35px;
					text-decoration: underline;
					margin-bottom: 22px;
				}

				a.title {

					transition: color $timeToColor;
					width: max-content;
					max-width: 100%;

					&:active {

						color: var(--medium-red-color);
					}
				}

				.item-link {

					display: block;
					position: relative;
					color: var(--text-medium);
					font-family: "Roboto", sans-serif;
					transition: color $timeToColor;
					margin-bottom: 15px;
					font-size: 18px;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
					width: max-content;
					max-width: 100%;
					
					&:last-child {

						margin-bottom: 22px;
					}
					
					svg {

						width: 18px;
						height: 18px;
						position: absolute;	
						right: calc(100% + 11.25px);
						top: 50%;
						transform: translateY(-50%);
					}

					&.has-link { 
					
						&:active {

							color: var(--medium-red-color);
						}
					}
				}

				.description {

					color: var(--text-medium);
					font-size: 18px;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
				}

				.columns {

					margin-top: 87px;
					display: flex;
					align-items: flex-end;
					
					.buttons {

						max-width: calc(100% - 52px);
						
						.btn {

							width: 100%;
							text-align: center;
							
							&:not(:last-child) {

								margin-bottom: 22px;
							}
						}
					}

					.link-globe {

						margin-bottom: 5px;
						margin-left: 13px;
						transition: transform $timeToColor;

						img {

							margin-bottom: 0;
							margin-left: 0;
						}

						&:active {

							transform: scale(1.05);
						}
					}

					img {

						display: block;
						width: 39px;
						height: auto;
						margin-bottom: 5px;
						margin-left: 13px;
					}
				}
			}
		}
	}

	.pagination-block {
	
		position: relative;

		.not-found {

			padding-left: 3 * $columnSizeDesktop;
			padding-right: 3 * $columnSizeDesktop;
			display: none;
			font-family: "Montserrat", sans-serif;
			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: 35px;
			letter-spacing: 0em;
			text-align: left;
			max-width: 720px;
		}

		&.not-found {

			.not-found {

				display: block;
			}

			.paginationjs {

				display: none;
			}
		}

		&.hide-pagination {

			.paginationjs {

				display: none;
			}			
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.grid {

			.item {

				.body {

					a.title {

						&:hover {

							color: var(--medium-red-color);
						}
					}
					
					.item-link {

						&.has-link {
						
							&:hover {

								color: var(--medium-red-color);
							}
						}
					}

					.columns {
						
						.link-globe {

							&:hover {

								transform: scale(1.05);
							}
						}
					}
				}	
			}
		}

		.paginationjs {

			.paginationjs-pages {

				ul {

					li {

						&:hover {

							a {

								color: var(--medium-red-color);
							}

							&.paginationjs-prev,
							&.paginationjs-next {

								&:not(.disabled) {
							
									a {
										
										background-color: var(--pink-color);

										svg {

											path {

												stroke: var(--medium-red-color);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 51px;
		padding-bottom: 48px;
		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;

		.grid {

			.item {

				margin-bottom: 31px;
				width: 100%;
				margin-left: 0;
				margin-right: 0;

				img {

					height: 12.2 * $columnSizeMobile;
					max-height: 300px;
				}

				.body {

					padding: 28px $columnSizeMobile 20px $columnSizeMobile;

					.location {

						font-size: 10px;
						font-style: normal;
						font-weight: 700;
						line-height: 12px;
						letter-spacing: 0.1em;
						margin-bottom: 5px;
					}

					.title {

						font-size: 16px;
						font-style: normal;
						line-height: 35px;
						margin-bottom: 20px;
					}

					.item-link {
						
						font-size: 14px;
						line-height: 24px;
						letter-spacing: 0.02em;
						margin-bottom: 6px;
						display: flex;
						align-items: center;

						&:last-child {

							margin-bottom: 31px;
						}

						svg {

							position: relative;
							top: unset;
							right: unset;
							transform: none;
							margin-right: 8px;
						}
					}

					.description {

						font-size: 14px;
						line-height: 21px;
						letter-spacing: 0.02em;
					}

					.columns {

						margin-top: 44px;

						img {

							margin-bottom: 4px;
						}
					}
				}
			}
		}

		.pagination-block {

			.not-found {

				width: 100%;
				padding-right: $columnSizeMobile;
				padding-left: $columnSizeMobile;
				font-size: 18px;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: 0em;
			}
		}

		.paginationjs {

			margin-top: 32px;
		}
	}
}