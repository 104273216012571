.posts {

	h2 {

		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: 65px;
		letter-spacing: 0em;
		margin-bottom: 40px;
		width: 50%;
	}

	.posts-columns {

		display: flex;
		flex-wrap: wrap;

		.column-mobile,
		.column-left,
		.column-right {

			width: 50%;

			.text-block {

				padding-left: 12.5%;
				padding-right: 12.5%;
				text-transform: uppercase;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.02em;
				margin-bottom: 120px;
			}

			.post-item {

				width: 100%;
				padding-left: 12.5%;
				padding-right: 12.5%;
				display: flex;
				flex-direction: column;
				position: relative;
				
				&:not(:last-child) {

					margin-bottom: 110px;	
				}

				.box-image {
					
					width: 100%;
					height: 11 * $columnSizeDesktop;
					border-radius: 10px;
					overflow: hidden;
					margin-bottom: 36px;

					img {

						transition: transform $timeToColor;
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.category-and-date {

					display: flex;
					align-items: center;
					margin-bottom: 20px;
					
					svg {

						display: block;
						height: 18px;
						width: 20px;
						margin-right: 5px;
					}

					p {

						display: block;
						font-size: 13px;
						font-style: normal;
						font-weight: 300;
						letter-spacing: 0.1em;
						text-transform: uppercase;
						margin-right: 5px;
						margin-left: 5px;

						&:first-child {

							margin-left: 0;
						}

						&.featured {

							font-weight: 700;						
							color: var(--medium-red-color);
						}
					}
				}

				.title {

					font-size: 25px;
					font-style: normal;
					font-weight: 600;
					line-height: 35px;
					letter-spacing: 0em;
					display: block;
					margin-bottom: 14px;
					transition: color $timeToColor;
					position: relative;

					&:before {

						content: '';
						position: absolute;
						background-color: var(--medium-red-color);
						height: 2px;
						width: min(57px, 2 * #{$columnSizeDesktop});
						left: -22px;
						transform: translateX(-100%);
						top: 14px;				
					}
				}

				.description {

					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
					text-align: left;
					color: var(--text-medium);
				}


				.item-link {

					&:after {

						content: '';
						position: absolute;
						top: 0;
						left: 12.5%;
						right: 12.5%;
						bottom: 0;
					}
				}

				&:active {

					.box-image {

						img {
						
							transform: scale(1.02);
						}
					}

					.title {

						color: var(--medium-red-color);
					}
				}
			}
		}

		.column-left {
		
			.post-item {
		
				&:first-child {

					margin-top: 120px;
				}
			}
		}

		.column-mobile {

			width: 100%;
			display: none;
		}
	}

	@media(min-width: $limit_mobile) {

		.posts-columns {

			.column-left,
			.column-right {

				.post-item {

					&:hover {

						.box-image {

							img {
							
								transform: scale(1.02);
							}
						}

						.title {

							color: var(--medium-red-color);
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		h2 {

			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 35px;
			width: 100%;
			margin-bottom: 20px;
		}

		.posts-columns {

			.column-left,
			.column-right {

				display: none;
			}

			.column-mobile {

				display: block;

				.text-block {

					margin-top: 22px;
					font-size: 12px;
					line-height: 20px;
					margin-bottom: 20px;
					padding-right: 2 * $columnSizeMobile;
					padding-left: 2 * $columnSizeMobile;
				}

				.post-item {

					width: 100%;
					padding-top: 0 !important;
					padding-left: $columnSizeMobile;
					padding-right: $columnSizeMobile;

					&:not(:last-child) {
						
						margin-bottom: 63px;
					}

					.box-image {

						height: 17.5 * $columnSizeMobile;
						margin-bottom: 22px;
					}

					.category-and-date {

						p {

							font-size: 10px;
						}
					}

					.title {

						font-size: 18px;
						line-height: 28px;
						margin-bottom: 12px;


						&:before {

							width: 15px;
							left: -2 * $columnSizeMobile;
							transform: translateX(0%);
							top: 10px;				
						}
					}

					.description {

						font-size: 14px;
						line-height: 26px;
					}

					.item-link {

						&:after {

							left: $columnSizeMobile;
							right: $columnSizeMobile;
						}
					}
				}
			}
		}
	}
}
