.search-units {

	padding: 80px (2 * $columnSizeDesktop);

	&.light {

		padding: 0px (4 * $columnSizeDesktop);
		background-color: var(--bg-medium);

		.content {

			background: transparent;
			border-radius: 0;

			.decoration-light {
				display: block;
			}

			.decoration-red {
				display: none;
			}

			.decoration {

				top: max(-2.95 * #{$columnSizeDesktop}, -85px);
				right: max(-4 * #{$columnSizeDesktop}, -119px);
				width: min(29 * #{$columnSizeDesktop}, 835.2px);
			}

			.form-content {

				min-height: min(18.4 * #{$columnSizeDesktop}, 529px);
				max-width: 784px;
				width: 27 * $columnSizeDesktop;
				padding-left: 0;
				padding-right: 0;

				h2 {

					color: var(--text-dark);
					margin-bottom: 22px;
				}

				p {

					color: var(--text-dark);
				}

				form {

					.input-group {

						border-bottom: 1.2px solid var(--text-dark);
						align-items: flex-end;

						input {	

							width: calc(100% - 14px - 34px);
							border-radius: 0;
							background-color: transparent;
							color: var(--text-dark);
							font-weight: 400;
							padding: 0;
							margin-bottom: 8px;

							&:-webkit-autofill::first-line {
								color: var(--text-dark) !important;
							}

							&::placeholder {

								font-weight: 400;
								color: var(--text-dark);
							}
						}
					}
				}
			}
		}
	}

	.content {

		position: relative;
		border-radius: 10px;
		background: var(--bg-gradient);

		.decoration {

			position: absolute;
			top: max(-25px, -2 * #{$columnSizeDesktop});
			right: max(-58px, -2 * #{$columnSizeDesktop});
			width: min(50 * #{$columnSizeDesktop}, 1440px);
			height: auto;
			
			svg {

				width: 100%;
				height: auto;
			}
		}

		.decoration-light {

			display: none;
			position: absolute;
		}

		.form-content {

			position: relative;
			z-index: 1;
			width: 24 * $columnSizeDesktop;
			max-width: 690px;
			min-height: min(19.6 * #{$columnSizeDesktop}, 566px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 108px;
			padding-bottom: 100px;
			padding-left: 2 * $columnSizeDesktop;
			padding-right: $columnSizeDesktop;

			h2 {

				font-size: 50px;
				font-weight: 600;
				line-height: 65px;
				letter-spacing: -0.01em;
				text-align: left;
				color: var(--text-light);
				margin-bottom: 35px;
			}

			p {	
				color: var(--text-light);
				font-size: 16px;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.02em;
				margin-bottom: 60px;
			}

			form {

				width: 100%;
				
				.input-group {

					display: flex;
					align-items: center;
					width: 100%;

					input {

						border: none;
						border-radius: 30px;
						width: calc(100% - 14px - 48px);
						margin-right: 14px;
						background-color: rgba(0, 0, 0, 0.1);
						color: var(--text-light);
						font-family: "Montserrat", sans-serif;
						font-size: 15px;
						font-style: normal;
						font-weight: 600;
						line-height: 18px;
						padding: 15px 18px;


						&:-webkit-autofill::first-line {
							font-family: "Montserrat", sans-serif !important;
							color: var(--text-light) !important;
						}

						&::placeholder {

							color: var(--text-light);
							font-family: "Montserrat", sans-serif;
							font-size: 15px;
							font-style: normal;
							font-weight: 600;
							line-height: 18px;
						}

						&:active,
						&:focus {

							outline: none;
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 40px ($columnSizeMobile);

		&.light {

			padding: 0px $columnSizeMobile;
			background-color: transparent;

			.content {

				background-color: var(--bg-medium);
				padding-top: min(14 * #{$columnSizeMobile}, 253px);
				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;

				.decoration {

					top: 18px;
					right: max(-1 * #{$columnSizeMobile}, -111110px);
					left: unset;
					width: min(25 * #{$columnSizeMobile}, 500px);
				}

				.form-content {

					padding-bottom: 73px;
					width: 100%;

					h2 {

						font-size: 18px;
						font-weight: 500;
						line-height: 24px;
						margin-bottom: 28px;
					}

					p {

						margin-bottom: 53px;
						font-size: 14px;
						line-height: 21px;
					}
				}
			}
		}

		.content {

			padding-top: min(18 * #{$columnSizeMobile}, 385px);
			
			.decoration {

				position: absolute;
				top: -1 * $columnSizeMobile;
				right: unset;
				left: -1 * $columnSizeMobile;
				width: min(25 * #{$columnSizeMobile}, 500px);
				height: auto;
			}

			.form-content {

				min-height: 0;
				width: 100%;
				padding-top: 7px;
				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;
				padding-bottom: 39px;

				h2 {

					font-size: 30px;
					font-weight: 600;
					line-height: 35px;
					margin-bottom: 19px;
				}

				p {

					margin-bottom: 28px;
					font-size: 12px;
					line-height: 20px;
					letter-spacing: 0.02em;
				}

				form {

					.input-group {

						input {

							width: calc(100% - 12px - 41px);
							margin-right: 12px;
							padding: 13px 10px;
							font-size: 12px;
							font-style: normal;
							font-weight: 600;
							line-height: 15px;

							&::placeholder {

								font-size: 12px;
								line-height: 15px;
							}
						}

						.btn-icon {

							width: 41px;
							height: 41px;

							svg {

								width: 15px;
							}
						}
					}
				}
			}
		}
	}
}