.schedule-calendar,
.contact {

	padding: 111px (2 * $columnSizeDesktop) 99px (2 * $columnSizeDesktop);
	background-color: var(--bg-medium);

	.columns {

		display: flex;

		.left-column {

			flex: 1;
			padding-left: 2 * $columnSizeDesktop;

			.text-content {

			    max-width: 880px;    
				padding-right: 2 * $columnSizeDesktop;

				h2 {

					font-size: 50px;
					font-weight: 600;
					line-height: 65px;
				}

				.description,
				.info {

					margin-top: 33px;
					font-size: 18px;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
					color: var(--text-medium);
				}

				.info {

					margin-top: 45px;
					margin-bottom: 20px;
				}

				.socials {

					padding-bottom: 20px;
					margin-bottom: -20px;

					a {

						display: flex;
						align-items: center;
						width: max-content;
						max-width: 100%;

						img {
							display: none;
						}

						svg {

							display: block;
							margin-right: 14px;
							min-width: 15px;
							width: 15px;
							height: auto;
						}

						span {

							transition: color $timeToColor;
							display: block;
							word-break: break-all;
							line-height: 32px;
							font-size: 18px;
							font-weight: 400;
							letter-spacing: 0.02em;
							color: var(--text-medium);
						}

						&.email {

							span {
								text-decoration: underline;
							}
						}

						&:not(:last-child) {

							margin-bottom: 6px;
						}

						&:active {

							span {

								color: var(--medium-red-color);
							}
						}
					}
				}
			}

			.desktop-only,
			.mobile-only {
			
				svg {

					margin-top: 60px;
					display: block;
					width: 100%;
					height: auto;
					max-width: 880px;
					margin-left: auto;
				}
			}
		}

		.right-column {

			align-self: flex-start;
			position: relative;
			overflow: hidden;
			margin-top: 78px;
			max-width: 880px;
			width: 54%;
			background-color: var(--bg-light);
			border-radius: 10px;
			padding: 40px;

			iframe {

				width: 100%;
				height: 640px;
			}

			.form-contact {

				form {

					select,
					input {

						width: 100%;
						padding: 8px 3px;
						border: none;
						border-bottom: 1.2px solid var(--black-color);
						font-size: 15px;
						font-weight: 400;
						line-height: 18px;
						background-color: transparent;
						color: var(--black-color);

						&::placeholder {

							color: var(--black-color);
						}

						&:not(:first-child) {

							margin-top: 40px;
						}
				
						&:focus {

							outline: none;
						}
					}

					label.error {

						display: block;
						color: red;
						margin-top: 10px;
						font-size: 15px;
					}

					label.textarea-placeholder {

						margin-top: 40px;
						display: block;
						width: 100%;
					
						span.title {

							display: block;
							width: 100%;
							padding: 8px 3px;
							font-size: 15px;
							font-weight: 400;
							line-height: 18px;
							color: var(--black-color);
						}

						textarea {

							width: 100%;
							margin-top: 0 !important;
							border: none;
							font-size: 15px;
							font-weight: 400;
							line-height: 18px;
							border-top: 1.2px solid var(--black-color);
							height: 150px;
							background-color: var(--bg-medium);
							padding: 10px;
							color: var(--black-color);

							&::placeholder {

								color: var(--black-color);
							}

							&:focus {

								outline: none;
							}

							& ~ span.title {

								color: blue;
							}
						}
					}	

					.feedback-error {

						font-size: 16px;
						color: red;
						line-height: 1.35;
						margin-top: 20px;
						margin-bottom: -10px;
					}		

					.btn {

						margin-top: 40px;
					}
				}

				.feedback-message {

					color: var(--medium-red-color);
					font-size: 18px;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
					padding: 50px 20px;
				}
			}

			&.form-schedule {

				select {

					width: 100%;
					height: 20px;
					border: none;
					border-bottom: 1.2px solid var(--black-color);
					font-size: 14px;
					font-weight: 400;
					line-height: 18px;
					background-color: transparent;
					color: var(--black-color);

					&:focus {

						outline: none;
					}
				}
				.no-after{
					background-color: rgb(0, 0, 0, 0.5) !important;
					opacity: 1 !important;
					&::after{
						content: none;
					}
				}
				
				
				.hidden{
					display: none !important;
				}
				.validate-error{
					background-color: #fff;
					padding: 30px 50px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 1rem;
					border-radius: 10px;
					p:nth-child(1){
						color: var(--medium-red-color);
                        font-size: 24px;
						font-weight: 700;
					}
					p{
						color: #000;
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 28px;
					}
					.btn{
						font-size: 16px;
						font-weight: 600;
						color: #fff !important;
						background-color: var(--medium-red-color);

						&:hover{
							background-color: var(--pink-color);
							color: var(--medium-red-color) !important;
							border: 1px solid var(--medium-red-color) !important;
						}
					}
				}

				form.only-responsible {

					.only-student {
						display: none;
					}
				}

				form.only-student {

					.only-responsible {
						display: none;
					}
				}

				label.error:not(.checkbox-container) {

					display: block;
					margin-bottom: 27px;
					font-size: 14px;
					font-weight: 300;
					letter-spacing: 0;
					line-height: 28px;
					margin-left: 28px;
					color: var(--medium-red-color);
				}

				.form-control {

					height: 20px;
					position: relative;
					margin-bottom: 40px;

					&.error {

						margin-bottom: 0px;
					}

					div.label {

						position: absolute;
						left: 0;
						top: calc(50% + -3px);
						transform: translateY(-50%);
						transition: transform 0.25s ease, color 0.25s ease, font-size 0.25s ease;
						font-size: 18px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 1;
						color: var(--black-color);
						font-weight: 300;
						pointer-events: none;
					}

					&:focus-within div.label, select.valid~div.label, input:valid~div.label, input:not(:placeholder-shown)~div.label, div.label:has(~ textarea:not(:placeholder-shown)) {
						
						transform: translateY(-170%);
						font-size: 12px;
						color: #9F9F9F;
					}

					input:not([type="submit"]),
					textarea {

						font-size: 14px;
						width: 100%;
						padding-bottom: 5px;
						border: none;
						border-bottom: 1px solid var(--black-color);

						&::placeholder, textarea::placeholder {

							color: transparent;
						}

						&:focus {

							outline: none;
						}

						&.error {

							margin-bottom: 0px;
							border-bottom: 1px solid var(--medium-red-color);
						}
					}

					textarea~div.label {

						top: 0;
						transform: translateY(28px);
					}

					&:focus-within textarea~div.label, textarea:not(:placeholder-shown)~div.label {

						transform: translateY(5px);
					}
				}

				.first-row {

					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 46px;

					.tip {

						font-size: 14px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 28px;
					}

					.steps {

						display: flex;
						align-items: center;

						.step {

							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: var(--light-gray-color);
							display: flex;
							align-items: center;
							justify-content: center;
							transition: transform $timeToAnimate ease-in-out;

							p {

								color: var(--text-medium);
								font-size: 24px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 32px;
								text-align: center;
								transition: color $timeToAnimate ease-in-out;
							}

							&.current {

								background-color: var(--pink-color);

								p {
									color: var(--dark-red-color);
								}
							}

							&.step-1:not(.current) {

								cursor: pointer;
							}
						}

						.connector {

							width: 30px;
							height: 2px;
							background-color: var(--black-color);
						}
					}
				}

				.form-box {

					position: relative;

					.question {

						font-size: 18px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 28px;
						margin-bottom: 11px;

						&.title-date {

							margin-top: 36px;
							margin-bottom: 0px;
							color: #DA0001;
							font-size: 22px;
							font-weight: 600;
						}
					}

					.no-results {

						font-size: 18px;
						color: var(--medium-red-color);
						margin-bottom: 40px;
					}

					.radio-options {

						display: flex;
						margin-bottom: 10px;

						.checkbox-container {

							&:not(:last-child) {

								margin-right: 49px;
							}
						}
					}

					.checkbox-container {

						position: relative;
						-webkit-user-selectize-input : none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
						cursor: pointer;
						display: flex;
						margin-bottom: 28px;

						input {

							position: absolute;
							opacity: 0;
							cursor: pointer;
							height: 0;
							width: 0;
						}

						.checkmark {

							margin-top: 3px;
							height: 20px;
							min-width: 20px;
							border: 1px solid var(--medium-red-color);
							margin-right: 7px;
							border-radius: 50%;
							position: relative;
							background-color: var(--white-color);
							display: flex;
							align-items: center;
							justify-content: center;

							.square {

								border-radius: 50%;
								transition: background-color $timeToColor ease-in-out;
								height: 10px;
								width: 10px;
								background-color: var(--white-color);
							}
						}

						input:checked ~ .checkmark {

							.square {

								background-color: var(--medium-red-color);
							}
						}

						p {

							width: 100%;
							font-size: 18px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 28px;

							a {

								transition: color $timeToColor ease-in-out;
								color: var(--light-text-color);
								text-decoration: underline;

								&:active {

									color: var(--medium-red-color);
								}

								@media(min-width: $limit_mobile + 1px) {

									&:hover {

										color: var(--medium-red-color);
									}
								}
							}
						}

						input.error ~ p {

							margin-bottom: -20px;
						}
					}

					.date-and-time {

						position: relative;
					}

					label.error:not(.checkbox-container) {

						display: block;
						margin-bottom: 20px;
						font-size: 14px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 28px;
						margin-left: 0px;
						color: var(--medium-red-color);
					}
					

					.datepicker {

						color: var(--black-color);
						background-color: #efefef;
						border-radius: 20px;
						margin-top: 33px;
						margin-bottom: 51px;
						padding-top: 37px;
						padding-left: 41px;
						padding-right: 41px;
						padding-bottom: 19px;

						.ui-datepicker-inline.ui-datepicker {

							background-color: transparent;
							width: 100%;
						}

						.ui-datepicker-header {

							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 24px;
							border: none;
							background: none;

							.ui-datepicker-next {

								order: 1;
							}

							.ui-datepicker-title {

								color: var(--dark-red-color);
								margin-right: 10px;
								margin-left: 10px;
								font-size: 20px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 28px;
							}

							.ui-datepicker-prev,
							.ui-datepicker-next {

								position: relative;
								width: 20px;
								height: 20px;
								cursor: pointer;

								span {

									display: none;
								}

								&:after {

									position: absolute;
									left: 50%;
									top: 50%;
									transform: translate(-50%, -50%);
									content: var(--chevron_url);
									// Content defined in base.html
									width: 8px;
									height: 14px;
								}
							}

							.ui-datepicker-prev {

								&:after {

									transform: translate(-50%, -50%) scaleX(-1);
								}
							}
						}

						.ui-widget-content {

							border: none;
						}

						table {

							width: 100%;

							th.ui-datepicker-week-col,
							td.ui-datepicker-week-col {

								display: none;
							}

							thead {

								th {

									font-size: 20px;
									font-weight: bold;
									letter-spacing: 0;
									line-height: 28px;
									text-align: center;
									padding-bottom: 18px;
								}
							}

							td {

								text-align: center;
								padding-bottom: 26px;

								span {

									padding: 0;
									text-align: center;
									font-size: 16px;

									&.ui-state-default {

										border: none;
										background: none;
									}
								}

								a {

									background-color: transparent;
									border: none;
									border-radius: 5px;
									display: flex;
									align-items: center;
									justify-content: center;
									width: 32px;
									height: 32px;
									margin-left: auto;
									margin-right: auto;
									color: var(--dark-text-color);
									text-decoration: none;
									font-size: 22px;
									font-weight: 500;
									letter-spacing: 0;
									line-height: 28px;
									text-align: center;

									&.ui-state-active {

										background-color: var(--dark-red-color);
										color: var(--white-color);
									}
								}

								&.ui-datepicker-week-end  {

									a {

										cursor: default;
										pointer-events: none;
										color: #CEDAF4;
									}
								}
							}
						}
					}

					.time-options {

						margin-top: 22px;
						margin-bottom: 18px;
						display: flex;
						flex-wrap: wrap;

						label {

							cursor: pointer;
							position: relative;
							margin-right: 24px;
							margin-bottom: 26px;

							span {

								text-align: center;
								display: block;
								padding: 10px 30px;
								border-radius: 20px;
								border: 1px solid var(--medium-red-color);
								transition: color $timeToColor ease-in-out, background-color $timeToColor ease-in-out;
								font-size: 18px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 1;
							}

							input {

								position: absolute;
								height: 0px;
								width: 0px;
								overflow: hidden;

								&:checked ~ span,
								&:focus ~ span {

									background-color: var(--dark-red-color);
									color: var(--white-color);
								}
							}
						}
					}

					.erro-dynamics {

						margin-top: 20px;
					    color: var(--dark-red-color);
						line-height: 150%;
						font-size: 20px;
					}

					&.step-2 {			

						input.btn {

							margin-top: 39px;
						}
					}
				}

				.step-success {

				   	height: 100%;

				   	div {

				   		display: flex;
				   		align-items: flex-end;
				   		height: 100%;

						p {

							color: var(--medium-red-color);
							font-size: 22px;
							font-weight: 500;
							letter-spacing: 0;
							line-height: 32px;

							strong {

								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}

	@media(min-width: $limit_mobile) {

		.columns {

			.left-column {
			
				.text-content {
			
					.socials {
			
						a {
							
							&:hover {

								span {
									color: var(--medium-red-color);
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 34px $columnSizeMobile 57px $columnSizeMobile;

		.columns {

			flex-direction: column;

			.left-column {

				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;
				width: 100%;
				flex: initial;

				.text-content {

					padding-right: 0;

					h2 {

						font-size: 30px;
						line-height: 35px;
					}

					.description,
					.info {

						margin-top: 32px;
						font-size: 14px;
						line-height: 21px;
					}

					.info {

						margin-top: 25px;
						margin-bottom: 15px;
					}

					.socials {

						a {

							svg {

								margin-right: 10px;
							}

							span {

								font-size: 14px;
								line-height: 21px;
							}
						}
					}
				}
				
				.desktop-only,
				.mobile-only {
				
					svg {

						margin-top: 43px;
						width: 73%;
						margin-left: 0;
					}
				}
			}

			.right-column {

				width: 100%;
				margin-top: 30px;
				padding: 34px $columnSizeMobile 37px $columnSizeMobile;

				.form-contact {

					.feedback-message {

						font-size: 16px;
						padding: 20px;
					}
				}
			}
		}
	}
}

.selectWrapper {

	--menu-height: 250px;

	position: relative;
	height: 23px;
	width: 100%;
	margin-bottom: 40px;

	div.label {

		position: absolute;
		z-index: 1;
	    color: var(--black-color);
	    left: 0;
	    top: 50%;
	    line-height: 1;
	    transform: translateY(-50%);
	    font-size: 18px;
	    font-weight: 300;
	    transition: transform 0.25s ease, font-size 0.25s ease, color 0.25s ease;
	    opacity: 0;
	    pointer-events: none;
	}

	&.filled {

		div.label {

			transform: translateY(-170%);
			font-size: 12px;
			color: #9F9F9F;
			opacity: 1;
		}

		.ui-selectmenu-button {

			&.ui-button {

				.ui-selectmenu-text {

					font-size: 14px;
					align-self: center;
					font-weight: 400;
				}
			}
		}

	}

	&.error {

		margin-bottom: calc(23px + 20px);

		.ui-menu {

			max-width: (calc(100% - 6px));
			margin-left: 2px;
		}

		label.error {

			position: absolute;
			bottom: -50%;
			margin-bottom: 0px !important;
		}
	}

	@media(max-width: $limit_mobile) {

		height: 41px;
		margin-bottom: 26px;

		div.label {

			font-size: 14px;
			left: 15px;
		}

		.ui-selectmenu-button {

			height: 41px;
			padding: 6.5px 15px;

			&.ui-button {

				.ui-selectmenu-icon.ui-icon {

					width: 8px;
					height: 14px;
					margin-top: 7px;
				}

				.ui-selectmenu-text {

					font-size: 14px;
					font-size: 'Roboto', sans-serif;
					color: var(--text-dark);
				}
			}
		}

		&.error {

			margin-bottom: 20px;

			.ui-selectmenu-button {

				border: 1px solid var(--medium-red-color);
			}
		}
	}
}

.ui-selectmenu-button {

	position: absolute;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	height: 23px;
	border-radius: 0;
	outline: none;
	z-index: 1;
	transition: height 0.5s ease;
	padding: 0;

	&.ui-button {

		width: 100%;
		background-color: white;
		border: none;

		.ui-selectmenu-icon.ui-icon {

			background-image: var(--chevron_url);
			// Background image defined in base.html
		    background-size: contain;
		    background-position: center;
		    background-repeat: no-repeat;
		    width: 14px;
		    height: 14px;
		    transform: rotate(90deg);
		    margin-top: 4px;
		    transition: transform 0.5s ease;
		}

		.ui-selectmenu-text {

			font-size: 18px;
			font-weight: 300;
			line-height: 1;
		}
	}

	&.ui-selectmenu-button-open {

		height: var(--menu-height);
		background-color: transparent;

		.ui-selectmenu-icon.ui-icon {

			transform: rotate(-90deg);
		}
	}
}

.ui-selectmenu-menu {

	display: block;
	top: 23px !important;
	left: 0;
	width: 100%;
	pointer-events: none;
	border-top: 1px solid var(--black-color);

	.ui-menu {

		border: none;
		transition: height 0.5s ease, opacity 0.5s ease;
		height: 0px;
		opacity: 0;

		.ui-menu-item {

			font-size: 14px;
			font-weight: 300;
			color: var(--black-color);
			background-color: #efefef;

			.ui-menu-item-wrapper {

				padding-left: 28px;
				padding-top: 10px;
				padding-bottom: 10px;

				&.ui-state-active {

					border: none;
					color: var(--black-color);
					font-weight: 300;
					background: #d8d8d8;
					margin: 0px;
				}
			}
		}
	}

	&.ui-selectmenu-open {

		pointer-events: all;

		.ui-menu {

			height: calc(var(--menu-height) - 90px);
			opacity: 1;
		}
	}

	@media (max-width: $limit_mobile) {

		.ui-menu {

			.ui-menu-item {

				font-size: 14px;

				.ui-menu-item-wrapper {

					padding-left: 15px;
				}
			}
		}
	}
}

.ui-widget-header .ui-icon.ui-icon-circle-triangle-w {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	text-indent: -9999px;
}

.ui-widget-header .ui-icon.ui-icon-circle-triangle-e {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	text-indent: -9999px;
}