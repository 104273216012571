.header-post {

	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;
	position: relative;

	.decoration {
		
		pointer-events: none;
		width: min(27 * #{$columnSizeDesktop}, 850.8px);
		margin-left: -3 * $columnSizeDesktop;
		margin-top: -4 * $columnSizeDesktop;
		transform: rotate(-1.5deg); 

		svg {

			width: 100%;
			height: auto;
		}
	}

	.columns {

		display: flex;
		width: 100%;

		.text-content {

			width: 57%;
			padding-top: 108px;
			padding-left: 2 * $columnSizeDesktop;
			padding-right: 2 * $columnSizeDesktop;
			padding-bottom: 91px;
			background-color: var(--bg-medium);

			.first-row {

				display: flex;
				align-items: center;
				margin-bottom: 22px;

				svg {

					margin-right: 11px;
					width: 20px;
					height: auto;
				}

				p {

					text-transform: uppercase;
					color: var(--medium-red-color);
					font-family: "Montserrat", sans-serif;
					font-size: 13px;
					font-style: normal;
					font-weight: 700;
					line-height: 16px;
					letter-spacing: 0.1em;
					text-align: left;
					margin-right: 10px;
				}

				span {

					display: block;
					margin-left: 10px;
					text-transform: uppercase;
					font-family: "Montserrat", sans-serif;
					font-size: 13px;
					font-style: normal;
					font-weight: 300;
					line-height: 16px;
					letter-spacing: 0.1em;
					text-align: left;
				}
			}

			h2 {

				font-size: 50px;
				font-style: normal;
				font-weight: 600;
				line-height: 65px;
				letter-spacing: 0em;
				text-align: left;
				margin-bottom: 36px;
			}

			.grid {

				display: flex;
				flex-wrap: wrap;

				.item {

					margin-top: 55px; 

					&:nth-child(2n-1) {

						width: 54%;
					}

					&:nth-child(2n) {

						width: 46%;
					}

					.item-title,
					.item-text {

						font-size: 18px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px;
						letter-spacing: 0.02em;
						text-align: left;
					}

					.item-title {

						text-transform: uppercase;
					}

					.reader {

						svg {

							width: 30px;
							height: auto;
						}
					}

					.social {

						display: flex;
						margin-top: 5px;

						a {
							display: block;

							svg {

								width: auto;
								height: 25px;

								path {

									transition: fill $timeToColor;
								}
							}

							&:not(:last-child) {

								margin-right: 17px;
							}

							&:active {

								svg {

									path {

										fill: var(--dark-red-color);
									}
								}
							}
						}
					}
				}
			}
		}

		.image-content {

			width: 43%;

			img {

				object-fit: cover;
				object-position: center;
				width: 100%;
				height: 100%;
				min-height: 600px;
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.columns {

			.text-content {

				.item {

					.social {

						a {

							&:hover {

								svg {

									path {

										fill: var(--dark-red-color);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;
		overflow: hidden;

		.decoration {

			margin-top: -8vw;
			position: relative;
			width: calc(100% + 2 * #{$columnSizeMobile});
			transform: none;
			bottom: unset;
			left: unset;
			margin-left: -1 * $columnSizeMobile;
			margin-right: -1 * $columnSizeMobile;
			height: 58vw;
		}

		.columns {

			flex-direction: column;

			.text-content {

				width: 100%;
				padding: 26px $columnSizeMobile;
				
				.first-row {

					margin-bottom: 21px;

					p {
						
						font-size: 10px;
						line-height: 12px;
						letter-spacing: 0.1em;
					}

					span {

						font-size: 10px;
						font-weight: 300;
						line-height: 12px;
						letter-spacing: 0.1em;
					}
				}

				h2 {

					font-size: 30px;
					font-style: normal;
					font-weight: 600;
					line-height: 35px;
					margin-bottom: 0;
				}

				.grid {

					.item {

						width: 100% !important;
						margin-top: 14px;

						.item-title,
						.item-text {

							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
							letter-spacing: 0.02em;
						}

						.reader {

							svg {

								width: 18px;
							}
						}

						.social {

							a {

								&:not(:last-child) {

									margin-right: 10px;
								}

								svg {

									height: 15px;
								}
							}
						}
					}
				}
			}

			.image-content {

				width: 100%;
				
				img {

					height: 18.5 * $columnSizeMobile;
					min-height: 0px;
					max-height: 400px;
				}
			}
		}
	}
}