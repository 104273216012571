.featured-posts {

	padding-top: 66px;
	padding-bottom: 132px;

	.btn {

		margin-right: auto;
		margin-left: auto;
		margin-top: 76px;
	}

	.posts {

		.not-found {

			margin-left: 3 * $columnSizeDesktop;
			margin-right: 3 * $columnSizeDesktop;
			font-family: "Montserrat", sans-serif;
			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: 35px;
			letter-spacing: 0em;
			max-width: 600px;
			margin-top: 35px;
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 59px;
		padding-bottom: 48px;

		.btn {

			margin-top: 46px;
			margin-left: 2 * $columnSizeMobile;
			margin-right: 2 * $columnSizeMobile;
		}

		.posts {

			.not-found {

				font-size: 18px;
				line-height: 24px;
				margin-top: 0;
				margin-right: 2 * $columnSizeMobile;
				margin-left: 2 * $columnSizeMobile;
			}
		}
	}
}