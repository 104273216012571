.content-and-video {

	position: relative;
	padding-top: 75px;
	padding-bottom: 127px;
	overflow: hidden;

	.decoration {

		width: 36 * $columnSizeDesktop;
		height: auto;

		svg {

			width: 100%;
			height: auto;
		}
	}

	.columns {

		margin-top: -100px;
		padding-left: 4 * $columnSizeDesktop;
		padding-right: 4 * $columnSizeDesktop;
		display: flex;
		align-items: flex-start;

		.text-content {

			padding-top: 160px;
			width: 55%;
			padding-right: 2 * $columnSizeDesktop;

			h2 {
				font-size: 50px;
				font-style: normal;
				font-weight: 600;
				line-height: 65px;
				letter-spacing: 0em;
			}

			p {
				
				margin-top: 37px;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.02em;
			}
		}

		.video-content {

			width: 45%;
			background-color: var(--bg-medium);
			border-radius: 10px;

			& > img {

				width: 100%;
				max-height: 550px;
				height: 18 * $columnSizeDesktop;
				border-radius: 10px;
			}

			.btn-open-video {

				border-radius: 10px;
				overflow: hidden;	
				width: 100%;
				max-height: 550px;
				height: 18 * $columnSizeDesktop;
				position: relative;
				cursor: pointer;

				.mask {

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(2, 2, 3, 0.28);
				}

				img {

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					transition: transform $timeToAnimate;
				}

				.play {

					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
					transform: translate(-50%, -50%);

					svg {

						width: 67px;
						height: auto;

						path {

							transition: stroke $timeToAnimate;
						}
					}
				}

				&:active {

					img {

						transform: scale(1.02);
					}

					.play {

						svg {

							path {

								stroke: var(--medium-red-color);
							}
						}
					}
				}
			}
		}
	}

	&.only-video {

		padding-top: 50px;
		padding-bottom: 50px;

		.decoration {

			display: none !important;
		}

		.columns {

			margin-top: 0;
			padding-left: 2 * $columnSizeDesktop;
			padding-right: 2 * $columnSizeDesktop;

			.text-content {

				display: none;
			}

			.video-content {

				width: 100%;

				& > img {

					height: 23.5 * $columnSizeDesktop;
					max-height: 682px;
					border-radius: 10px 10px 0px 0px;
				}

				.btn-open-video {

					height: 23.5 * $columnSizeDesktop;
					max-height: 682px;
					border-radius: 10px 10px 0px 0px;

					.mask {

						background-color: transparent;
						background: linear-gradient(105.72deg, rgba(168, 24, 21, 0.56) -0.81%, rgba(37, 49, 60, 0) 100%);
					}

					.play {

						transform: none;
						top: min(2 * #{$columnSizeDesktop}, 54px);
						left: $columnSizeDesktop;
					}
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.columns {

			.video-content {

				.btn-open-video {

					&:hover {

						img {

							transform: scale(1.02);
						}

						.play {

							svg {

								path {

									stroke: var(--medium-red-color);
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 0;
		padding-bottom: 45px; 

		.decoration {

			width: 100%;

			svg {

			    margin-top: -7%;
			    margin-bottom: -2%;
			    max-height: 380px;
			}
		}

		.columns {

			margin-top: 0;
			flex-direction: column;
			padding-left: $columnSizeMobile;
			padding-right: $columnSizeMobile;

			.text-content {

				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;
				padding-top: 0;
				width: 100%;

				h2 {

					font-size: 30px;
					line-height: 35px;
				}

				p {

					color: var(--text-medium);
					margin-top: 14px;
					font-size: 14px;
					line-height: 26px;
				}
			}

			.video-content {

				width: 100%;
				margin-top: 51px;

				& > img {

					height: 22 * $columnSizeMobile;
					max-height: 400px;
				}

				.btn-open-video {

					height: 22 * $columnSizeMobile;
					max-height: 400px;

					.play {
						
						svg {
							
							width: min(3 * #{$columnSizeMobile}, 67px);
						}
					}
				}
			}
		}

		&.only-video {

			padding-top: 30px;
			padding-bottom: 30px;

			.columns {

				.video-content {

					margin-top: 0;

					& > img {
						
						height: 22 * $columnSizeMobile;
						max-height: 400px;
					}
					
					.btn-open-video {

						height: 22 * $columnSizeMobile;
						max-height: 400px;

						.play {

							top: 25px;
							left: $columnSizeMobile;
						}
					}
				}
			}
		}
	}
}