#modal-media {

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999;
	display: none;

	.background {

		background-color: var(--bg-light);
		width: 100%;
		height: 100%;
	}

	img,
	iframe {

		position: absolute;
		top: calc(50% + (1 * #{$columnSizeDesktop}));
		left: 50%;
		width: 44 * $columnSizeDesktop;
		height: 23.4 * $columnSizeDesktop;
		max-height: calc(100% - ( 4 * #{$columnSizeDesktop}));
		transform: translate(-50%, -50%);
		object-fit: cover;
		display: none;
	}


	.btn-icon {

		position: absolute;
		display: none;
		top: calc(50% - 48px);

		&.btn-next {
			right: 10px;
		}

		&.btn-previous {
			left: 10px;
		}
 	}

	.btn-close-modal {

		cursor: pointer;	
		position: absolute;
		top: $columnSizeDesktop;
		right: 3 * $columnSizeDesktop;
		padding: 0px;
		background-color: transparent;
		border: none;

		svg {

			width: $columnSizeDesktop;
			height: $columnSizeDesktop;
			
			path {

				transition: stroke $timeToColor;
				stroke: var(--medium-red-color);
			}
		}

		&:active {

			svg {

				path {

					stroke: var(--dark-red-color);
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.btn-close-modal {

			&:hover {
			
				svg {

					path {

						stroke: var(--dark-red-color);
					}
				}
			}
		}
	}


	@media(max-width: $limit_mobile) {

		.btn-close-modal {

			top: 2 * $columnSizeMobile;
			right: 2* $columnSizeMobile;

			svg {

				width: 48px;
				height: 48px;
			}
		}

		.btn-icon {

			top: 2 * $columnSizeMobile;

			&.btn-next {

				right: unset;
				left: calc(2 * #{$columnSizeMobile} + 48px + 10px);
			}

			&.btn-previous {
				left: 2 * $columnSizeMobile;;
			}
	 	}

		img,
		iframe {

			top: calc(50% + (2 * #{$columnSizeMobile}));
			width: 23 * $columnSizeMobile;
			height: 30 * $columnSizeMobile;
			max-height: calc(100% - ( 6 * #{$columnSizeMobile}));
		}
	}
}