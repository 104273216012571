.animated-homepage {

	position: relative;

	.sidebar {

		position: absolute;
		z-index: 2;
		top: 0;
		left: 3 * $columnSizeDesktop;
		width: 15 * $columnSizeDesktop;
		transform: translateY(-50%);

		.item {

			width: max-content;
			cursor: pointer;
			position: relative;
			padding-top: 20px;
			padding-bottom: 50px;
			padding-left: $columnSizeDesktop;

			.index {

				position: absolute;
				top: 0;
				left: 0;
				transform: translate(-50%, -50%);
				width: 64px;
				height: 64px;
				
				.circle {

					height: 100%;
					width: 100%;
					border-radius: 50%;
					border: 1px solid var(--medium-red-color);
					transition: opacity $timeToColor;	
				}

				span {

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-family: "Montserrat", sans-serif;
					font-size: 15px;
					font-weight: 500;
					line-height: 1;
					letter-spacing: 0.02em;
					transition: opacity $timeToAnimate;

					&.dark {
						color: var(--black-color);
					}

					&.light {
						color: var(--white-color);
					}
				}
			}

			.text-box {

				position: relative;
				
				p {

					font-family: "Montserrat", sans-serif;
					font-size: 25px;
					font-style: normal;
					font-weight: 500;
					line-height: 35px;
					transition: opacity $timeToAnimate;
					transform-origin: top left;

					&.controller {

						visibility: hidden;
					}

					&.dark {

						position: absolute;
						top: 0;
						left: 0;
						color: var(--medium-red-color);
					}

					&.light {

						position: absolute;
						top: 0;
						left: 0;
						color: var(--white-color);
					}
				}

				&.active {

					.index {

						border-color: var(--medium-red-color);
					}

					p {
						font-size: 50px;
						line-height: 65px;
					}
				}
			}
		}
	}

	.first-block {

		padding-top: 60px;
		padding-bottom: 126px;
		padding-left: 24 * $columnSizeDesktop;
		padding-right: 4 * $columnSizeDesktop;
		min-height: min(26.666 * #{$columnSizeDesktop}, 768px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		scroll-snap-align: start;
		scroll-snap-stop: always;

		h2 {

			font-size: 50px;
			font-weight: 600;
			line-height: 65px;
			margin-bottom: 50px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 35px;
			letter-spacing: 0.02em;
			margin-bottom: 60px;
		}
	}

	.block-with-bg-image {

		position: relative;
		width: 100%;
		scroll-snap-align: start;
		scroll-snap-stop: always;
		
		img {

			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.mask {
		
			background: linear-gradient(105.72deg, rgba(37, 49, 60, 0.6) -0.81%, rgba(37, 49, 60, 0.4) 49.45%, rgba(168, 24, 21, 0.2) 100%);
			transform: matrix(-1, 0, 0, 1, 0, 0);
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.content {

			position: relative;
			z-index: 1;
			width: 100%;
			height: calc(100vh - 92px);
			padding-left: 25 * $columnSizeDesktop;
			padding-right: 5 * $columnSizeDesktop;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 35px;
			letter-spacing: 0.02em;
			color: var(--white-color);
			text-transform: uppercase;
			display: flex;
			align-items: center;
		}
	}

	.carousel {

		.item {

			height: 100%;
			margin-right: $columnSizeMobile;
			display: flex;
			flex-direction: column;

			.text-content {

				flex: 1;
				padding-top: 40px;
				padding-bottom: 35px;
				padding-left: 2 * $columnSizeMobile;
				padding-right: $columnSizeMobile;
				background-color: var(--bg-medium);

				.index {

					width: 64px;
					height: 64px;
					border: 1px solid var(--medium-red-color);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: "Montserrat", sans-serif;
					font-size: 12px;
					font-weight: 500;
					line-height: 1;
					letter-spacing: 0.02em;
					margin-bottom: 9px;
				}

				h4 {

					font-size: 30px;
					font-weight: 600;
					line-height: 35px;
					color: var(--medium-red-color);
				}

				p {

					margin-top: 17px;
					font-size: 12px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0.02em;
				}
			}

			.image-content {

				width: 100%;
				height: 20.8 * $columnSizeMobile;
				max-height: 400px;
				position: relative;

				img {

					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:after {

					content: '';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					transform: matrix(-1, 0, 0, 1, 0, 0);
					background: linear-gradient(105.72deg, rgba(37, 49, 60, 0.6) -0.81%, rgba(37, 49, 60, 0.4) 49.45%, rgba(168, 24, 21, 0.2) 100%);
				}
			}
		}	
	}

	@media(max-width: $limit_mobile) {

		padding-bottom: 57px;

		.first-block {

			padding-left: 2 * $columnSizeMobile;
			padding-right: 2 * $columnSizeMobile;
			padding-top: 36px;
			padding-bottom: 59px;

			h2 {

				font-size: 30px;
				line-height: 35px;
				margin-bottom: 28px;
			}

			p {

				font-size: 12px;
				line-height: 20px;
				margin-bottom: 18px;
			}
		}
	}
}

@media(max-width: $limit_mobile) {
	html {
		scroll-snap-type: none !important;
	}
}