.simple-text-and-button {

	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 8 * $columnSizeDesktop;
	padding-right: 4 * $columnSizeDesktop;

	p {
		font-size: 18px;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: 0.02em;
	}

	.btn {

		&:nth-child(2) {

			margin-top: 55px;
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 2 * $columnSizeMobile;
		padding-right: 2 * $columnSizeMobile;

		p {

			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.02em;
		}

		.btn {

			&:nth-child(2) {

				margin-top: 25px;
			}
		}
	}
}