.page-header {

	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;
	display: flex;
	width: 100%;
	background-color: var(--bg-medium);

	.text-content {

		width: 55%;
		padding-top: 111px;
		padding-bottom: 77px;
		padding-left: 2 * $columnSizeDesktop;
		padding-right: 3 * $columnSizeDesktop;

		h2 {

			color: var(--medium-red-color);
			font-size: 50px;
			font-weight: 600;
			line-height: 65px;
			margin-bottom: 86px;
		}

		.subtitle {

			font-size: 50px;
			font-weight: 600;
			line-height: 65px;
			margin-bottom: 35px;
		}

		.description {

			font-size: 18px;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: 0.02em;
		}

		.btn-primary {

			margin-top: 37px;
		}
	}

	.image-content {

		width: 45%;

		img {

			width: 100%;
			height: min(23.6 * #{$columnSizeDesktop}, 682px);
			object-position: center;
			object-fit: cover;
		}
	}

	@media(max-width: $limit_mobile) {

		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;
		flex-direction: column;
		background-color: transparent;

		.text-content {

			background-color: var(--bg-medium);
			padding-left: $columnSizeMobile;
			padding-right: $columnSizeMobile;
			width: 100%;
			padding-top: 29px;
			padding-bottom: 36px;
			
			h2 {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 17px;
			}

			.subtitle {

				font-size: 30px;
				line-height: 35px;
				margin-bottom: 22px;
			}

			.description {

				font-size: 14px;
				line-height: 26px;
				margin-bottom: 0px;
			}
		}

		.image-content {

			width: 100%;

			img {

				height: 25 * $columnSizeMobile;
				max-height: 400px;
			}
		}
	}
}