@import "./reset.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./buttons.scss";
@import "./link.scss";
@import "./globals.scss";
@import "./loading.scss";
@import "./floating-buttons.scss";
@import "./posts.scss";
@import "./cookies.scss";
@import "./modal-media.scss";
@import "./paginationjs.scss";
@import "./section-404.scss";

@import "./select2-search-dropdown.css";

@import "./plugins/header.scss";
@import "./plugins/footer.scss";
@import "./plugins/callout.scss";
@import "./plugins/featured-posts.scss";
@import "./plugins/content-and-image-side-by-side.scss";
@import "./plugins/carousel-one-column.scss";
@import "./plugins/carousel-three-columns.scss";
@import "./plugins/background-image-and-content.scss";
@import "./plugins/content-and-video.scss";
@import "./plugins/quote.scss";
@import "./plugins/dropdown.scss";
@import "./plugins/card-one-column.scss";
@import "./plugins/segments.scss";
@import "./plugins/page-header.scss";
@import "./plugins/square-image-video.scss";
@import "./plugins/search-units.scss";
@import "./plugins/results-units.scss";
@import "./plugins/header-post.scss";
@import "./plugins/content-block.scss";
@import "./plugins/testimony.scss";
@import "./plugins/images-side-by-side.scss";
@import "./plugins/blog.scss";
@import "./plugins/other-posts.scss";
@import "./plugins/card-two-columns.scss";
@import "./plugins/title-and-description.scss";
@import "./plugins/simple-text-and-button.scss";
@import "./plugins/media-gallery.scss";
@import "./plugins/grid-gallery.scss";
@import "./plugins/map.scss";
@import "./plugins/animated-homepage.scss";
@import "./plugins/contact.scss";
@import "./plugins/schedule-seb.scss";
@import "./plugins/forms-contact.scss";
@import "./plugins/embed.scss";
@import "./plugins/animated-numbers.scss";
@import "./lp-agendamento-maple.scss";
@import "./plugins/custom-menu.scss";


@import "./markdown-content.scss";

html.with-smooth {
	
	scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, input, li, button, a, p, span, i, strong, div, input, textarea {

	font-family: 'Roboto', sans-serif;
	color: var(--text-dark);
}

input, textarea {

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			transition: background-color 5000s ease-in-out 0s,
				color 5000s ease-in-out 0s;
		}
}

h1, h2, h3, button, a {

	font-family: 'Montserrat', sans-serif;
}

a {
	
	text-decoration: none;
}