.dropdown {

	padding-left: 4 * $columnSizeDesktop;
	padding-right: 4 * $columnSizeDesktop;
	padding-bottom: 80px;

	h2 {

		margin-top: 164px;	
		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: 65px;
	}

	.columns {

		display: flex;
		margin-top: 64px;

		.image-content {

			width: 28.5%;

			img {

				width: 100%;
				height: 17 * $columnSizeDesktop;
				max-height: 495px;
				object-fit: cover;
				object-position: center;
				border-radius: 10px;
			}
		}

		.image-content-mobile {
			display: none;
		}

		.list-content {

			width: 71.5%;
			padding-left: 4 * $columnSizeDesktop;

			.dropdown-item {

				background-color: var(--bg-medium);
				border-radius: 30px;
				overflow: hidden;

				.dropdown-title {

					padding: 13px 10px 13px 25px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					cursor: pointer;
					position: relative;

					.title {

						font-family: 'Montserrat', sans-serif;
						display: flex;
						align-items: center;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 35px;
						margin-right: 15px;

						svg {

							display: block;
							margin-right: 26px;
							width: 33px;
							height: auto;
						}
					}

					.btn-icon {

						width: 38px;
						height: 38px;

						svg {
							
							width: 13px;
							height: auto;
							transition: transform $timeToAnimate;
						}

						&.btn-download {

							position: initial;

							&:after {

								position: absolute;
								content: '';
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
							}

							svg {

								width: 20px;
								height: 20px;
							}
						}
					}

					&:active {

						.btn-icon {

							background-color: var(--pink-color);
							border-color: var(--medium-red-color);

							svg {

								path {

									stroke: var(--medium-red-color);
								}
							}

							&:before {

								opacity: 0;
							}
						}
					}
				}

				.dropdown-content {

					overflow: hidden;
					transition: max-height $timeToAnimate, opacity $timeToAnimate;
					max-height: 0px;
					opacity: 0;

					.content {

						padding-top: 21px;
						padding-left: 58px;
						padding-right: 58px;
						padding-bottom: 36px;
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px;
						letter-spacing: 0.02em;
						text-align: left;
					}
				}

				&.show {

					.dropdown-title {

						.btn-icon {

							svg {
								
								transform: rotate(-180deg);
							}
						}
					}

					.dropdown-content {

						opacity: 1;
					}
				}

				&:not(:last-child) {
					
					margin-bottom: 44px;
				}
			}
		}
	}

	&.without-image {

		.columns {

			.image-content {

				display: none;
			}

			.list-content {

				width: 100%;
				padding-left: 0;
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.columns {

			.list-content {

				.dropdown-item {

					.dropdown-title {

						&:hover {

							.btn-icon {

								background-color: var(--pink-color);
								border-color: var(--medium-red-color);

								svg {

									path {

										stroke: var(--medium-red-color);
									}
								}

								&:before {

									opacity: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;
		padding-bottom: 43px;


		h2 {

			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 35px;
			letter-spacing: 0em;
			margin-top: 33px;
			margin-right: $columnSizeMobile;
			margin-left: $columnSizeMobile;
		}

		.columns {

			margin-top: 0;
			flex-direction: column;

			.image-content {

				width: 100%;
				margin-top: 21px;

				img {

					height: 13.3 * $columnSizeMobile;
					max-height: 400px;
				}
			}

			.image-content-flag-mobile {
				display: none;
			}

			.image-content-mobile {

				margin-top: 10px;
				width: 100%;
				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;
				display: block;

				img {

					width: 100%;
					height: auto;
				}
			}

			.list-content {

				width: 100%;
				padding-left: 0px;
				padding-top: 34px;

				.dropdown-item {

					border-radius: 20px;

					.dropdown-title {

						padding: 12px 13px 11px 14px;

						.title {

							font-size: 12px;
							font-style: normal;
							line-height: 18px;
							
							svg {

								width: 17px;
								margin-right: 7px;
							}
						}

						.btn-icon {

							width: 22px;
							height: 22px;

							svg {

								width: 8px;
							}

							&.btn-download {

								svg {

									width: 16px;
									height: 16px;
								}
							}
						}
					}

					.dropdown-content {

						.content {

							padding-top: 10px;
							padding-left: 25px;
							padding-right: 27px;
							padding-bottom: 20px;
							font-size: 12px;
							line-height: 18px;
						}
					}

					&:not(:last-child) {

						margin-bottom: 17px;
					}
				}
			}
		}
	}
}