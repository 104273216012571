.map {

	padding-bottom: 137px;

	.first-block {

		min-height: 500px;
		width: 100%;
		position: relative;
		overflow: hidden;

		.decoration {

			position: absolute;
			top: 0;
			right: 0;
			width: min(39 * #{$columnSizeDesktop}, 1126px);
			height: auto;
			transform: translateY(max(-18.5%, -104px));

			svg {

				width: 100%;
				height: auto;
			}
		}

		.text-content {

			max-width: min(23 * #{$columnSizeDesktop}, 604px);
			margin-left: 4 * $columnSizeDesktop;
			padding-top: 137px;
			padding-bottom: 90px;

			h2 {
				font-size: 50px;
				font-weight: 600;
				line-height: 65px;
			}

			p {

				margin-top: 31px;
				font-size: 18px;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.02em;
			}
		}
	}

	.map-block {

		padding-left: 6 * $columnSizeDesktop;
		padding-right: 6 * $columnSizeDesktop;

		.map-box {

			svg {

				width: 100%;
				height: auto;

				.area.active {

					path {
							
						fill: var(--medium-red-color);
					}

					&#ca-area {

						path {
							
							fill: var(--dark-red-color);
						}
					}
				}

				.country {

					.label,
					.marker {

						pointer-events: none;
					}

					.en {

						display: none;
					}

					&.active {
						
						.label {

							opacity: 0;
							transition: opacity $timeToColor;
						}

						.marker {

							opacity: 1;
							pointer-events: initial;
						}	

						&#ca,
						&.expanded,
						&:hover {

							.label {

								opacity: 1;
							}							
						}
					}
				}
			}
		}

		&.english {
		
			.map-box {

				.country {

					.en {

						display: block;
					}

					.pt {

						display: none;
					}
				}
			}
		}

		.tip {

			margin-top: 37px;
			font-family: "Montserrat", sans-serif;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0em;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-left: 2 * $columnSizeMobile;
			padding-right: 2 * $columnSizeMobile;

			svg {

				display: block;
				margin-left: 10px;
				width: 23px;
				height: auto;

				path {

					stroke: var(--black-color);
				}
			}
		}
	}

	.last-block {

		width: 100%;
		padding-top: 51px;
		display: flex;
		flex-wrap: wrap;

		.item {

			margin-top: 40px;
			padding-left: 4 * $columnSizeDesktop;
			padding-right: 4 * $columnSizeDesktop;
			width: 33.333%;
			
			.text-1 {

				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.02em;
				margin-bottom: 8px;
				text-transform: uppercase;
			}

			.text-2 {

				font-family: "Montserrat", sans-serif;
				font-size: 76px;
				font-style: normal;
				font-weight: 600;
				line-height: 65px;
				color: var(--medium-red-color);
			}

			.text-3 {

				margin-top: 16px;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.02em;
			}
		}
	}

	@media(min-width: 1600px) {

		.last-block {

			.item {

				margin-top: 80px;
			}
		}
	}
	
	@media(max-width: 1200px) {

		.map-block {

			padding-left: 3 * $columnSizeDesktop;
			padding-right: 3 * $columnSizeDesktop;
		}
	}


	@media(max-width: $limit_mobile) {

		padding-bottom: 56px;

		.first-block {

			min-height: 185px;
			
			.decoration {
			
				width: 320px;
				height: auto;
				transform: translateY(-30px);
			}

			.text-content {

				margin-left: 2 * $columnSizeMobile;
				margin-right: 8 * $columnSizeMobile;
				padding-top: 94px;
				padding-bottom: 36px;
				max-width: initial;

				h2 {

					font-size: 30px;
					line-height: 35px;
				}

				p {
					margin-top: 18px;
					font-size: 14px;
					line-height: 26px;
				}
			}
		}

		.map-block {
			
			padding-left: 0;
			padding-right: 0;
			
			.map-box {

				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;
				overflow-x: auto;

				svg {

					width: 900px;
				}
			}
		}

		.last-block {

			padding-top: 9px;

			.item {

				margin-top: 42px;
				padding-left: 2 * $columnSizeMobile;
				padding-right: 5 * $columnSizeMobile;
				width: 100%;

				.text-1 {

					font-size: 12px;
					line-height: 20px;
					margin-bottom: 18px;
				}

				.text-2 {

					font-size: 60px;
					line-height: 35px;
				}	

				.text-3 {

					font-size: 14px;
					line-height: 26px;
					margin-top: 18px;
				}
			}
		}
	}

	@media(max-width: 500px) {

		.first-block {

			.text-content {

				margin-right: 2 * $columnSizeMobile;
				padding-top: 185px;
			}
		}
	}
}

	