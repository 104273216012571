.scheduleSeb {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 55px;

    &.is-loading {

        .loading-mask {

            display: flex;
	        background-color: rgba(0, 0, 0, 0.1);
        }
    }

    span, label, .erro-dynamics, .erro-cpf {
        font-family: 'Montserrat', sans-serif;
    }

    .erro-dynamics, .erro-cpf {
        margin-bottom: 20px;
    }

    .error {
        color: #FF0000;
    }

    @media(max-width: 680px) {
        flex-direction: column;
    }

    .blkColumnInfos {
        display: inline-block;
        vertical-align: top;
        width: $columnSizeDesktop * 17;
        @media(max-width: 680px) {
            display: block;
            width: $columnSizeDesktop * 50;
        }

        .blkFoto {
            svg {
                margin-top: 78px;
                display: block;
                width: 100%;
                height: auto;
                max-width: 880px;
                margin-left: 118px;
            }

            picture {
                display: inline-block;
                img {
                    display: block;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }
            &.mobile {
                display: none;
                @media(max-width: 680px) {
                    display: block;
                    margin-bottom: 20px;
                }
            }
            &.desktop {
                display: block;
                @media(max-width: 680px) {
                    display: none;
                }
            }
        }

        .blkBreadCumb {
            display: block;
            width: $columnSizeDesktop * 10;
            margin-left: $columnSizeDesktop * 3;
            margin-top: 80px;
            margin-bottom: 55px;
            @media(max-width: 768px) {
                width: $columnSizeDesktop * 14;
            }
            @media(max-width: 680px) {
                width: $columnSizeDesktop * 46;
                margin-top: 0;
                margin-bottom: 25px;
            }
            nav {
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style-type: none;
                        font-family: 'Montserrat', sans-serif;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 1.0625rem;
                        line-height: normal;
                        color: #414042;
                        margin-right: 6px;
                        @media(max-width: 1024px) {
                            font-size: 14px;
                        }
                        svg {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 4px;
                        }
                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        .blkInfos {
            display: block;
            width: $columnSizeDesktop * 16;
            margin-left: $columnSizeDesktop * 3;
            @media(max-width: 680px) {
                width: $columnSizeDesktop * 46;
                margin: auto;
            }
            h1 {
                font-weight: 800;
                font-size: 56px;
                line-height: 1.5;
                color: #dd0000;
                margin-bottom: 30px;
                @media(max-width: 1024px) {
                    font-size: 42px;
                }
                @media(max-width: 768px) {
                    font-size: 32px;
                    margin-bottom: 20px;
                }
            }
            .texto {
                font-size: 1.0625rem;
                line-height: normal;
                letter-spacing: 2%;
                color: #414042;
                margin-bottom: 40px;
                @media(max-width: 1024px) {
                    font-size: 1rem;
                }
            }
        }

    }
    .blkColumnForm {
        display: inline-block;
        vertical-align: top;
        width: $columnSizeDesktop * 29;
        margin-left: $columnSizeDesktop * 3;
        @media(max-width: 680px) {
            display: block;
            width: $columnSizeDesktop * 46;
            margin: auto;
        }

        .blkFoto {
            position: relative;
            z-index: 1;
            display: block;
            text-align: right;
            picture {
                display: inline-block;
                img {
                    display: block;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }
            @media(max-width: 680px) {
                display: none;
            }
        }

        .blkForm {
            position: relative;
            z-index: 2;
            margin-top: -85px;
            display: block;
            width: $columnSizeDesktop * 27;
            @media(max-width: 1024px) {
                margin-top: -40px;
            }
            @media(max-width: 768px) {
                margin-top: 0;
            }
            @media(max-width: 680px) {
                width: $columnSizeDesktop * 46;
            }

            .atendimento_telefone {

                margin-top: 50px;
                font-weight: bold;
                font-size: 20px;
                line-height: 25px;

                p{
                    color: #dd0000;
                    font-family: 'Montserrat', sans-serif;
                }

                .info {
                    display: none;
                }

                .socials {
                    a {
                        display: block;
                        margin-top: 20px;
                        font-size: 28px;
                        font-weight: bold;
                        color: #5F5F5F;
                        text-decoration: none;
                        transition: color 0.25s ease;
    
                        img {
    
                            width: 20px;
                            height: auto;
                            margin-right: 5px;
                        }

                        svg {
                            display: none;
                        }
    
                        &:hover {
                            
                            span {
                                color: #dd0000;
                            }
                            text-decoration: underline;
                        }
                    }
                }

            }

            .stepNav {
                display: block;
                margin-bottom: 40px;
                ul {
                    position: relative;
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: rgba($color: #C0C0C0, $alpha: .4);
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    li {
                        position: relative;
                        z-index: 1;
                        list-style-type: none;
                        width: 42px;
                        height: 42px;
                        display: inline-block;
                        border: rgba($color: #C0C0C0, $alpha: .4) 1px solid;
                        font-size: 1.25rem;
                        line-height: normal;
                        text-align: left;
                        margin-right: 16px;
                        border-radius: 10px;
                        background-color: #fff;
                        &:last-child {
                            margin-right: 0;
                        }
                        div {
                            display: block;
                            text-align: center;
                            padding: 6px 0;
                            color: rgba($color: #C0C0C0, $alpha: .4);

                        }
                        &.active {
                            border-color: #dd0000;
                            background-color: #dd0000;
                            
                            div {
                                color: #fff ;
                            }
                        }
                    }
                }
            }

            .step {
                position: relative;
                width: 100%;
                height: 0;
                overflow: hidden;
                transition: all 1s ease;
                &.active {
                    height: auto;
                }
            }

            form {
                margin: 0;
                .inBlock {
                    display: inline-flex;
                    align-items: center;
                    margin-right: 10px;
                    font-size: 1rem;
                    color: #414042;
                    margin-bottom: 20px;
                    &:first-child {
                        margin-right: 20px;
                    }
                    div {
                        display: inline-flex;
                        margin-right: 20px;
                    }
                }
                .titleMod {
                    margin-bottom: 15px;
                    .inBlock {
                        margin: 0;
                    }
                }

                .no-results{
                    font-family: 'Montserrat', sans-serif;
                    display: none;
                    margin-bottom: 20px;
                }

                .erroSelecao {
                    font-size: 10px;
                    color: #FF0000;
                    display: block;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    display: none;
                }
                .calendario {
                    display: block;
                    width: 100%;
                    margin-bottom: 40px;
                    background-color: rgba(200, 200, 200, 0.2);
                    border-radius: 20px;
                    padding: 40px;
                    .datepicker-schedule {
                        display: flex;
                        width: 100%;
                        margin: auto;
                        .ui-widget{
                            width: 100%;
                            font-family: 'Montserrat', sans-serif;
                            &.ui-widget-content {
                                background-color: transparent;
                                border: 0;
                            }
                            .ui-datepicker-header {
                                background-color: transparent;
                                border: 0;
                                a {
                                    span {
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                    }
                                    &.ui-datepicker-prev {
                                        span {
                                            background-image: var(--left-chevron_url);
                                        }
                                    }
                                    &.ui-datepicker-next {
                                        span {
                                            background-image: var(--right-chevron_url);
                                        }
                                    }
                                }
                                .ui-datepicker-title {
                                    font-size: 1.25rem;
                                    letter-spacing: 5%;
                                    line-height: normal;
                                    text-transform: uppercase;
                                    margin-bottom: 20px;
                                    span {
                                        font-weight: 800;
                                        color: #dd0000;
                                    }
                                }
                            }
                            .ui-datepicker-calendar {
                                thead {
                                    tr {
                                        th {
                                            width: calc(100% / 7);
                                            font-size: 1rem;
                                            font-weight: bold;
                                            line-height: 1.875rem;
                                            font-family: 'Montserrat', sans-serif;
                                            text-transform: uppercase;
                                            span {
                                                color: #666;
                                            }
                                        }
                                    }
                                }
                                tbody {
                                    a {
                                        width: 36px;
                                        height: 36px;
                                        border: 0;
                                        padding: 9px 0;
                                        border-radius: 5px;
                                        color: #dd0000;
                                        font-size: 1.125rem;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                        line-height: 1.1875rem;
                                        text-align: center;
                                        background-color: transparent;
                                        margin: auto;
                                        &.ui-state-active {
                                            background-color: #dd0000;
                                            color: #fff;
                                        }
                                    }
                                    .ui-state-disabled {
                                        width: 35px;
                                        height: 36px;
                                        border: 0;
                                        padding: 9px 0;
                                        border-radius: 50%;
                                        color: #414042;
                                        font-size: 1rem;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                        line-height: 1.1875rem;
                                        text-align: center;
                                        background-color: transparent;
                                        margin: auto;
                                        border: 0;
                                        .ui-state-default {
                                            border: 0;
                                            text-align: center;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                        }
                    }
                } 
                .hour-options {
                    display: flex;
                    flex-wrap: wrap; 
                    width: 100%;  
                    margin-bottom: 40px;
                    .hour {
                        display: inline-block;
                        font-size: 1rem;
                        line-height: normal;
                        color: #414042;
                        border: #414042 1px solid;
                        border-radius: 15px;
                        padding: 14px 30px;
                        transition: all .1s ease;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        font-family: Montserrat, sans-serif;
                        &:hover {
                            cursor: pointer;
                            background-color: #414042;
                            color: #fff;
                        }
                        &.active {
                            border-color: #dd0000;
                            background-color: #dd0000;
                            color: #fff;
                        }
                    }
                }
            } 

            .erroAgendamento {
                font-size: 1rem;
                padding: 15px 0;
                margin: 20px 0;
                color: #FF0000;
                display: none;
            }

        }

        .success-form {

            display: none;
            margin-top: 80px;
            font-size: 16px;
            line-height: normal;

            div, strong {
                font-family: Montserrat, sans-serif;
            }

            @media(max-width: 680px) {
                margin-top: 0;
            }

            strong {

                color: #dd0000;
                font-size: 20px;
            }
        }

    }

    button {

        display: block;
        width: auto;
        padding: 14px 32px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        font-size: 1.25rem;
        line-height: normal;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        background-color: #dd0000;
        border-radius: 15px;
        text-align: center;
        border: 0;
        outline: none;
        box-shadow: none;
        letter-spacing: 1px;
        transition: all .2s ease;
    
        @media(max-width: 680px) {
            font-size: 1rem;
        }
    
        &:hover {
            cursor: pointer;
            background-color: #770400;
        }

        &:disabled {
            background-color: #d98d8a;
            &:hover {
                cursor: default;
                background-color: #d98d8a;
            }
        }
    }

    //checkbox
    .checkbox-container {

        position: relative;
        -webkit-user-selectize-input : none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        display: flex;
        margin-bottom: 28px;

        input {

            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {

            height: 22px;
            min-width: 22px;
            border: 2px solid white;
            margin-right: 7px;
            border-radius: 5px;
            position: relative;
            background-color: white;

            .square {

                border-radius: 3px;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: #414042 1px solid;
            }

            &:before {
                transform: rotate(-55deg);
                top: 0.8rem;
                left: 0.37rem;
            }

            &:after {
                transform: rotate(35deg);
                bottom: 0.35rem;
                left: 0.2rem;
            }

            &:before, &:after {
                content: "";
                /* display: inline-block; */
                background: #fff;
                width: 0; 
                height: 0.2rem;
                position: absolute;
                transform-origin: 0% 0%;
            }
        }

        input:checked ~ .checkmark {

            .square {

                background-color: #dd0000;
                border: #dd0000 1px solid;

            }

            &:before {
                width: 1rem;
                height: 0.15rem;
                transition: width .1s;
                transition-delay: .3s;
            }

            &:after {
                width: 0.4rem;
                height: 0.15rem;
                transition: width .1s;
                transition-delay: .2s;
            }
        }

        p {

            width: 100%;
            font-size: .875rem;
            line-height: 1.2rem;
            letter-spacing: 0;
            font-family: Montserrat, sans-serif;
            color: #414042;

            a {

                color: black;
                text-decoration: underline;

                &:active {

                    color: #dd0000;
                }

                @media(min-width: 638px) {

                    &:hover {

                        color: #dd0000;
                    } 
                }
            }
        }

        input.error ~ p {

            margin-bottom: -20px;
        }
    }

    //inputs
    .form-group {

        display: block;
        margin-bottom: 22px;
    
        &.dFlex {
            display: flex;
        }
    
        .form-control {
    
            box-shadow: none;
            outline: none;
            color: #908c8c;
            display: block;
            width: 100%;
            height: auto;
            border: #BEBEBE 1px solid;
            border-radius: 15px;
            font-size: 1rem;
            line-height: normal;
            padding: 15px 20px;
            font-family: Montserrat, sans-serif;

            &.error { 
                border: #FF0000 1px solid;
                color: #FF0000;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #FF0000;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #FF0000;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #FF0000;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: #FF0000;
                }
            }
    
            @media(max-width: 680px) {
                font-size: 1rem;
            }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #908c8c;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #908c8c;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: #908c8c;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: #908c8c;
            }
            &:focus {
                outline: none;
            }
            &.successField {
    
                border-color: #dd0000;
            }
        }
    }

    //radio
    [type="radio"] {
        &:checked, &:not(:checked) {
            position: absolute;
            left: -9999px;
        }
      
        &:checked + label, &:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: #414042;
            font-size: 1rem;
        }
      
        &:checked + label:before, &:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border: 1px solid #2B6244;
            border-radius: 5px;
            background: #fff;
        }
      
        &:checked + label:after {
            content: '';
            width: 22px;
            height: 22px;
            background: #dd0000;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
      
        &:not(:checked) + label:after {
            content: '';
            width: 12px;
            height: 12px;
            background: #dd0000;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
      
        &:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    //select
    select {

        background-color: #eee;
    
        &.loaded {
    
            background-color: transparent;
        }
    
        &.form-control {
    
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: var(--select-arrow) !important;
            background-position: 96% center !important;
            background-repeat: no-repeat !important;

        }
        
        option {
    
            text-transform: uppercase;
        }
    }

}