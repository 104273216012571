.popup-cookies {

	position: fixed;
	bottom: 100%;
	right: 3 * $columnSizeDesktop;
	background: var(--bg-light);
	padding: 84px 33px 34px 41px;
	width: 17 * $columnSizeDesktop;
	box-shadow: 5px 10px 30px rgba(80, 68, 68, 0.3);
	border-radius: 10px;
	transition: transform $timeToAnimate, opacity $timeToAnimate;
	opacity: 0;
	z-index: 999;
	display: flex;
	flex-wrap: wrap;

	&.show {
	
		transform: translateY(calc(100% + 117px));
		opacity: 1;
	}

	p, a {
		
		font-family: "Roboto", sans-serif;
		font-size: 17px;
		font-style: normal;
		font-weight: 300;
		line-height: 30px;
		letter-spacing: 0.02em;
		margin-bottom: 15px;
	}

	p {

		width: 100%;

		strong {

			font-size: 600;
		}
	}

	a {
	
		text-decoration: underline;
		transition: color $timeToColor;

		&:active {

			color: var(--medium-red-color);
		}
	}

	.btn-close {

		cursor: pointer;
		border: none;
		position: absolute;
		top: 26px;
		right: 26px;
		width: 21px;
		height: 21px;
		padding: 0;
		background-color: transparent;
	}

	.btn {

		margin-top: 15px;
		
		&.accept {

			margin-right: 15px;
		}
	}


	@media(min-width: $limit_mobile + 1px) {

		a {

			&:hover {

				color: var(--medium-red-color);
			}
		}
	}

	@media(max-width: $limit_mobile) {

		width: 21 * $columnSizeMobile;
		right: 2 * $columnSizeMobile;
		padding: 18px 16px;
		padding-top: 50px;

		&.show {
		
			transform: translateY(calc(100% + 83px));
		}

		.btn-close { 
			
			top: 18px;
			right: 16px;
			width: 16px;
			height: 16px;
		}

		p, a {

			font-size: 14px;
			line-height: 26px;
		}
	}
}