.blkHeader {
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 4;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

  a {
    margin: 0 (3 * 2vw);
    display: block;
    width: max-content;
    padding-top: 10px;
    padding-bottom: 10px;

    img {
      display: block;
      object-fit: contain;
    }
  }

  @media (max-width: 991px) {
    padding: 5px 0;

    a {
      img {
        max-width: 190px;
      }
    }
  }
}

.templateVideo {
  position: relative;
  z-index: 1;
  display: block;
  // width: 2vw * 50;
  padding: 80px 0;

  .embedResponsive {
    position: relative;
    display: block;
    width: 2vw * 44;
    padding: 0;
    overflow: hidden;
    margin: auto;
    @media (max-width: 1024px) {
      max-width: 100%;
    }

    &::before {
      display: block;
      content: "";
      padding-top: 56.25%;
    }
    .embedVideo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

.templateUnidades {
  .blkContent {
    padding: 40px 0;
    background-color: #f2f2f2;

    .blkContainer {
      width: 2vw * 46;
      margin: auto;

      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 1.5;
        margin-bottom: 30px;
        color: #dd0000;
      }

      .blkListaUnidades {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .blkUnidade {
          margin-right: 10px;
          margin-left: 10px;
          width: calc(33.333% - (40px / 3));
          display: block;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          picture {
            display: block;
            position: relative;
            overflow: hidden;

            img {
              border: 2px solid #dd0000;
              display: block;
              width: 100%;
              height: auto;
              aspect-ratio: 581/390;
              object-fit: cover;
            }
          }

          h3 {
            text-align: center;
            font-family: "Montserrat", sans-serif;
            font-size: 32px;
            font-weight: 700;
            line-height: normal;
            color: #dd0000;
            margin-top: 20px;
          }

          p.text-1 {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: #999;
            line-height: 1.25;
            margin-top: 1px;
            margin-bottom: 5px;
          }

          p.text-2 {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: #777;
            line-height: 1.25;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .blkContent {
      .blkContainer {
        h2 {
          font-size: 32px;
          text-align: center;
        }

        .blkListaUnidades {
          .blkUnidade {
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;

            h3 {
              font-size: 24px;
            }

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

.templateTopicos {
  display: block;
  width: 100%;
  height: auto;
  padding: 75px 0;
  background-color: #950000;

  @media (max-width: 680px) {
    padding: 75px 0 45px;
  }

  .blkContent {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    width: 2vw * 42;
    height: auto;
    margin: auto;

    @media (max-width: 1024px) {
      width: 2vw * 44;
    }

    @media (max-width: 680px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .blkColumn {
      display: block;

      .blkTopico {
        display: block;
        margin-bottom: 60px;
        @media (max-width: 680px) {
          margin-bottom: 30px;
        }

        .icone {
          display: inline-block;
          vertical-align: middle;
          width: 70px;
          height: auto;

          img {
            max-width: 100%;
            height: auto;
          }
        }
        .info {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 200px);
          height: auto;
          margin-left: 2vw * 2;
          color: #fff;
          font-weight: 600;
          font-size: 1.875rem;
          line-height: normal;
          @media (max-width: 1024px) {
            font-size: 1.5rem;
          }
          @media (max-width: 768px) {
            width: calc(100% - 130px);
            font-size: 20px;
          }
        }
      }
    }
  }
}

footer {
  display: block;
  width: 100%;

  .blkFooter {
    display: block;
    width: 100%;
    height: auto;
    padding: 35px 0;
    margin: auto;
    background-color: #fff2f2;

    @media (max-width: 680px) {
      padding-top: 50px;
      padding-bottom: 0;
    }

    .blkContent {
      display: block;
      width: 2vw * 40;
      height: auto;
      margin: auto;

      @media (max-width: 1024px) {
        width: 2vw * 44;
      }

      h3 {
        color: #535453;
        font-size: 1rem;
        margin-bottom: 10px;
        font-weight: 800;
        text-transform: uppercase;
      }

      .texto {
        font-size: 0.9375rem;
        line-height: normal;
        color: #535453;
        margin-bottom: 12px;
      }

      a {
        &.linkButton {
          display: block;
          width: 100%;
          padding: 8px 15px;
          text-transform: uppercase;
          text-decoration: none;
          color: #fff;
          font-size: 0.9375rem;
          line-height: normal;
          font-weight: bold;
          background-color: #dd0000;
          border-radius: 4px;
          text-align: center;
          transition: background-color 0.25s ease;

          &:hover {
            background-color: #770400;
          }
        }
      }

      nav {
        ul {
          li {
            list-style-type: none;

            a {
              color: #535453;
              font-size: 0.9375rem;
              line-height: normal;
              text-decoration: none;
              transition: color 0.25s ease;

              &:hover {
                color: #ad0600;
              }
            }
          }
        }

        &.redes {
          ul {
            li {
              display: inline-block;
              margin-right: 10px;

              svg {
                path {
                  fill: #ad0600;
                  transition: fill 0.25s ease;
                }

                &:hover {
                  path {
                    fill: #770400;
                  }
                }
              }
            }
          }
        }
      }

      .first-columns-box {
        display: inline-block;
        width: 2vw * 17.5;
        margin-right: 2vw;

        @media (max-width: 680px) {
          width: 100%;
          margin: 0;
        }

        .blkColumn1 {
          display: inline-block;
          vertical-align: top;
          width: 2vw * 9;
          padding-right: 30px;

          @media (max-width: 680px) {
            width: 2vw * 44;
            margin: auto auto 40px;
            padding-right: 0px;
          }
        }

        .blkColumn2 {
          display: inline-block;
          vertical-align: top;
          width: 2vw * 8;

          @media (max-width: 680px) {
            width: 2vw * 44;
            margin: auto auto 40px;
          }

          a {
            color: #dd0000;
            font-weight: bold;
            font-size: 13px;
            text-decoration: none;
            transition: text-decoration 0.25s ease;

            svg {
              width: 17px !important;
              height: 17px !important;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .address {
          border-top: 3px solid #ddd;
          margin-top: 30px;
          padding-top: 30px;

          @media (max-width: 680px) {
            margin-top: 0;
          }

          .blkColumn1,
          .blkColumn2 {
            h3 {
              text-transform: none;
            }

            a {
              color: #dd0000;
              font-weight: 600;
              font-size: 14px;
              text-decoration: none;
              transition: text-decoration 0.25s ease;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .blkColumn3 {
        display: inline-block;
        vertical-align: top;
        width: 2vw * 10;

        @media (max-width: 1024px) {
          width: 2vw * 12;
        }

        @media (max-width: 680px) {
          width: 2vw * 44;
          margin: auto auto 40px;
        }
      }

      .blkColumn4 {
        display: inline-block;
        vertical-align: top;
        width: 2vw * 9;
        margin-left: 2vw * 2;

        @media (max-width: 1024px) {
          width: 2vw * 8;
          margin-left: 2vw * 2;
        }

        @media (max-width: 768px) {
          width: 2vw * 8;
        }

        @media (max-width: 680px) {
          width: 2vw * 44;
          margin: auto auto 40px;
        }
      }
    }
  }
}

.atendimento_telefone {

  margin-top: 50px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #dd0000;

  a {
    display: block;
    margin-top: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #5F5F5F;
    text-decoration: none;
    transition: color 0.25s ease;

    img {

      width: 20px;
      height: auto;
      margin-right: 5px;
    }

    &:hover {

      color: #dd0000;
      text-decoration: underline;
    }
  }
}


.agendamento {

  width: 1200px;
  padding: 200px 60px;

  @media (max-width: 680px) {
    padding: 50px 50px 100px 50px;
  }

  & > h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 65px;
    text-align: center;
    letter-spacing: 1px;

    @media (max-width: 680px) {
      font-size: 34px;
      font-weight: 500;
      line-height: 40px;
    }
  }

  & > h2 {
    margin-top: 15px;
    font-family: Montserrat;
    color:#dd0000 !important;
    font-size: 24px;
    font-weight: 300;
    line-height: 29.26px;
    letter-spacing: 0.1em;
    text-align: center;

    @media (max-width: 680px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .btn {
    display: inline-block;

    & span {
      text-transform: uppercase;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.btn-primary {
      
      @media (max-width: 680px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    &.btn-secondary {

      border: 1px solid #CC1316;

      & span {
        color: #CC1316;
      }
      
      @media (max-width: 680px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .buttons {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .img-confirmacao {
    margin-top: 25px;
    margin-bottom: 25px;
  }

}
.lp-schedule{

  &.reschedule{
    .left-column{
      .text-content{
        .description{
          font-size: 24px;
          color: #CC1316;
        }
      }
    }
  }

  input[type=checkbox] + .checkmark{
    border-radius: 20% !important;
    .square{
      border-radius: 20% !important;
    }
  }
  
  .date-and-time{
    margin-bottom: 28px;
    .datepicker{
      background-color: #FFF !important;
      border: 1px solid var(--black-color);
      // .ui-datapicker-next::after{
      //   content: url("/static/images/right-red-chevron.svg");
      // }
      .ui-datepicker-year{
        display: none;
      }
      .ui-datepicker-month{
        text-transform: uppercase;
      }
      table{

        td{
          a{
            color: #A81815  !important;
            font-size: 16px !important;
            &.ui-state-active{
              color: var(--white-color) !important;
              border-radius: 50% !important;

              &:hover{
                background-color: #A81815  !important;
                color: var(--white-color) !important;
    
              }
            }

            &:hover{
              color: #A81815  !important;
              border: 1px solid #A81815 !important;
              background-color: #FFE3DF !important;
              border-radius: 50% !important;
            }
          }
        }
      }
      @media (max-width: 680px) {
      
        padding: 0 !important;
        table{
          th[scope="col"]{
            span{
              font-size: 16px !important;
              line-height: 22px !important;
            }
          }
        }
      }
    }
    .question{
      font-size: 18px;
      color: #504444;
    }
    .secondary-question{
      font-size: 24px !important;
      @media (max-width: 680px) {
        font-size: 18px !important;
      }
    }
  }
  .time-options {

    margin-top: 22px;
    margin-bottom: 18px;
    display: flex;
    flex-wrap: wrap;

    label {

      cursor: pointer;
      position: relative;
      margin-right: 24px;
      margin-bottom: 26px;

      span {

        text-align: center;
        display: block;
        padding: 10px 30px;
        border-radius: 20px;
        border: 2px solid #CC1316;
        color: #CC1316;
        transition: color $timeToColor ease-in-out, background-color $timeToColor ease-in-out;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1;
      }

      input {

        position: absolute;
        height: 0px;
        width: 0px;
        overflow: hidden;
        
        &:hover ~ span,
        &:focus ~ span {
          background-color: #FFE3DF;
        }
      }
    }
    @media (max-width: 680px) {
      gap: 20px;
      .col-4.w-100{
        width: calc(33.333% - (40px / 3)) !important;
        display: inline-block !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 5px !important;
        max-width: calc(33.333% - (40px / 3)) !important;
        &:first-child {
          margin-left: 0 !important;
        }
  
        &:last-child {
          margin-right: 0 !important;
        }
        span{
          padding: 10px 0px !important;
        }
      }
    }
  }
}
