.d-flex {

	display: flex;
}

.d-none {
	
	display: none;
}

.justify-center {
	justify-content: center;
}

.align-center {

	align-items: center;
}

.flex-wrap {

	flex-wrap: wrap;
}

.flex-justify-between {

	justify-content: space-between;
}

.w-100 {

	width: 100%;
}

.ml-auto {

	margin-left: auto;
}

.mr-auto {

	margin-right: auto;
}

.h-auto {

	height: auto;
}

.img-fluid {

	max-width: 100%;
	height: auto;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

@for $i from 0 through 100 {

	.col-#{$i} {

		display: block;
		max-width: $i * $columnSizeDesktop;
	}

	.mb-#{$i} {

		margin-bottom: $i * $columnSizeDesktop;
	}

	.ml-#{$i} {

		margin-left: $i * $columnSizeDesktop;
	}

	.mr-#{$i} {

		margin-right: $i * $columnSizeDesktop;
	}

	.p-#{$i} {

		padding: $i * $columnSizeDesktop;
	}

	.pl-#{$i} {

		padding-left: $i * $columnSizeDesktop;
	}

	.pr-#{$i} {

		padding-right: $i * $columnSizeDesktop;
	}
}


@media(min-width: $limit_mobile + 1px) {

	.mobile-only {

		display: none !important;
	}
}

@media(max-width: $limit_mobile) {

	.desktop-only {

		display: none !important;
	}

	@for $i from 0 through 50 {

		.col-mb-#{$i} {

			display: block;
			max-width: $i * $columnSizeMobile;
		}

		.mb-mb-#{$i} {

			margin-bottom: $i * $columnSizeMobile;
		}

		.ml-mb-#{$i} {

			margin-left: $i * $columnSizeMobile;
		}

		.mr-mb-#{$i} {

			margin-right: $i * $columnSizeMobile;
		}

		.p-mb-#{$i} {

			padding: $i * $columnSizeMobile;
		}

		.pl-mb-#{$i} {

			padding-left: $i * $columnSizeMobile;
		}

		.pr-mb-#{$i} {

			padding-right: $i * $columnSizeMobile;
		}
	}
}