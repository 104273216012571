.paginationjs {

	.paginationjs-pages {

		ul {

			display: flex;
			align-items: center;
			justify-content: center;

			li {

				line-height: 0;

				a {
					
					font-size: 18px;
					font-weight: 400;
					letter-spacing: 0.02em;
					text-align: center;
					transition: color $timeToColor;

					&:active {

						color: var(--medium-red-color);
					}
				}

				&.active {

					a {

						color: var(--medium-red-color);
						font-weight: 700;
						line-height: 32px;
					}
				}

				&.paginationjs-prev,
				&.paginationjs-next {

					a {

						box-shadow: 0px 0px 10px rgba(223, 56, 19, 0.2);
						width: 48px;
						height: 48px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: var(--medium-red-color);
						border-radius: 50%;
						transition: background-color $timeToColor, border-color $timeToColor, box-shadow $timeToColor;
						border: 1px solid var(--medium-red-color);

						svg {

							width: 18px;
							height: auto;

							path {

								transition: stroke $timeToColor;
								stroke: var(--white-color);
							}
						}
					}


					&.disabled {

						a {

							border-color: var(--bg-medium);
							background-color: var(--bg-medium);
							box-shadow: none;

							svg {

								path {

									stroke: #A3A097;
								}
							}
						}
					}

					&:not(.disabled) {

						a {
							&:active {

								background-color: var(--pink-color);

								svg {

									path {

										stroke: var(--medium-red-color);
									}
								}
							}
						}
					}
				}

				&:not(:last-child) {

					margin-right: 15px;
				}

				&.paginationjs-prev {

					margin-right: 25px;

					a {

						transform: scaleX(-1);
					}
				}

				&.paginationjs-next {

					margin-left: 10px;
				}					
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.paginationjs-pages {

			ul {

				li {

					&:hover {

						a {

							color: var(--medium-red-color);
						}

						&.paginationjs-prev,
						&.paginationjs-next {

							&:not(.disabled) {
						
								a {
									
									background-color: var(--pink-color);

									svg {

										path {

											stroke: var(--medium-red-color);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}


	@media(max-width: $limit_mobile) {

		.paginationjs-pages {

			ul {

				li {
					
					a {
						
						font-size: 14px;
						font-weight: 400;
					}

					&.active {

						a {

							font-weight: 400;
						}
					}

					&.paginationjs-prev,
					&.paginationjs-next {

						a {

							width: 35px;
							height: 35px;
						
							svg {

								width: 13px;
							}
						}
					}

					&:not(:last-child) {

						margin-right: 10px;
					}

					&.paginationjs-prev {

						margin-right: 15px;
					}

					&.paginationjs-next {

						margin-left: 5px;
					}								
				}
			}
		}
	}
}