.grid-gallery {

	padding: 70px 0;

	.grid-container {

		width: 92vw;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		gap: 40px;
	}
	
	.block-text {

		width: 35%;
		align-self: center;

		h2 {

			color:var(--medium-red-color);
			font-size: 50px;
			font-weight: 600;
			line-height: 65px;
		}

		p {

			font-size: 18px;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: 0.02em;
			margin-top: 36px;
		}
	}

	.block-grid {

		display: grid;
		flex: 1;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;

		.item {

			cursor: pointer;
			display: flex;
			height: 100%;
			flex-direction: column;
			justify-content: flex-end;

			.legend {

				display: none;
			}

			.banner {

				position: relative;
				overflow: hidden;
				border-radius: 10px;

				.mask {

					pointer-events: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(105.72deg, rgba(168, 24, 21, 0.56) -0.81%, rgba(37, 49, 60, 0) 100%);
				}

				& > img {

					width: 100%;
					height: auto;
					aspect-ratio: 1920 / 1080;
					border-radius: 10px;
					object-fit: cover;
					display: block;
				}
			

				.btn-open-video {

					border-radius: 10px;
					overflow: hidden;	
					width: 100%;
					aspect-ratio: 1920 / 1080;
					position: relative;
					cursor: pointer;

					
					img {

						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						transition: transform $timeToAnimate;
					}

					.play {

						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 1;
						transform: translate(-50%, -50%);

						svg {

							width: 67px;
							height: auto;

							path {

								transition: stroke $timeToAnimate;
							}
						}
					}

					&:active {

						img {

							transform: scale(1.02);
						}

						.play {

							svg {

								path {

									stroke: var(--medium-red-color);
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		.grid-container {
			flex-direction: column;
		}

		.block-text {

			width: 100%;			
			
			h2 {

				font-size: 30px;
				line-height: 1.3;
			}

			p {

				font-size: 16px;
				line-height: 1.35;
				margin-top: 20px;
			}
		}
	}

	@media(max-width: 700px) {

		.block-grid {
			grid-template-columns: 1fr 1fr;
		}
	}
}