.section-404 {

	padding-top: 129px;
	padding-bottom: 174px;
	padding-left: 4 * $columnSizeDesktop;
	padding-right: 4 * $columnSizeDesktop;

	h2 {
		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: 65px;
		letter-spacing: 0em;
		text-align: center;
		margin-bottom: 10px;
	}

	.subtitle {

		color: var(--medium-red-color);
		font-family: "Montserrat", sans-serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: 35px;
		letter-spacing: 0.02em;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 100px;
	}

	.description {

		font-family: "Montserrat", sans-serif;
		font-size: 25px;
		font-style: normal;
		font-weight: 500;
		line-height: 35px;
		letter-spacing: 0em;
		text-align: center;
		margin-bottom: 50px;
	}

	.btn {

		margin-left: auto;
		margin-right: auto;
	}

	@media(max-width: $limit_mobile) {

		padding-left: 2 * $columnSizeMobile;
		padding-right: 2 * $columnSizeMobile;
		padding-top: 55px;
		padding-bottom: 77px;

		h2 {
			font-size: 30px;
			line-height: 35px;
			margin-bottom: 40px;
		}

		.subtitle {

			font-size: 22px;
			line-height: 35px;
			margin-bottom: 60px;
		}

		.description {
			
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 30px;
		}
	}
}