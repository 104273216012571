.link {

	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	text-decoration: underline;
	transition: color $timeToColor;
	color: var(--text-medium);
	display: block;
	width: max-content;

	&.back {

		display: flex;
		align-items: center;

		svg {

			transform: scaleX(-1);
			margin-right: 13px;
			width: 18px;
			height: auto;
		}
	}

	&.download {

		color: var(--medium-red-color);
		display: flex;
		align-items: center;

		svg {

			margin-left: 10px;
			width: 15px;
			height: auto;
			transition: transform $timeToColor;

			path {

				transition: stroke $timeToColor;
			}
		}

		&:active {

			color: var(--dark-red-color);
			
			svg {
				
				transform: scale(1.1);

				path {

					stroke: var(--dark-red-color);
				}
			}
		}
	}

	&:active {

		color: var(--medium-red-color);
	}

	@media(min-width: $limit_mobile + 1px) {
		
		&:hover {

			color: var(--medium-red-color);

			&.download {

				color: var(--dark-red-color);
			
				svg {
					
					transform: scale(1.1);

					path {

						stroke: var(--dark-red-color);
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		font-size: 12px;
		line-height: 15px;
	}
}