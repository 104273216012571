.testimony {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 30px 0 60px;

    > div {
        width: 100%;
        margin: auto;
        display: flex;
    }

    .carousel {
        display: flex;
    }

    .tns-outer {
        width: 95%;
    }
    
    .btn-left, .btn-right {
        border: none;
        margin: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
    }

    .testimony-item {
        margin-top: 50px;

        > div {
            background-color: #F6F6F5;
            width: 90%;
            display: flex !important;
            flex-direction: row;
            padding: 75px 0 15px 60px;
            border-radius: 9px;
            height: 100%;
            margin: auto;
            margin-left: 42px;

            
    
            .testimony-text {
                width: 60%;
                padding-right: 30px;

                .quote-icon {
                    position: absolute;
                    top: 5px;
                }
                
                .testimony-quote {
                    color: #686868;
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 38px;

                    &.limit-quote {
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 6; 
                        line-clamp: 6; 
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    &.show-read-more {
                        + .read-more {
                            display: block;
                        }
                    }
                }

                .testimony-name {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
                    color: #CC1316;
                    text-transform: uppercase;
                    margin-top: 8px;
                }

                .read-more, .read-less {
                    color: #CC1316;
                    display: none;
                    cursor: pointer;
                }
            }
    
            .testimony-image {
                width: 40%;
                display: flex;
                align-items: center;

                img {
                    object-fit: cover;
                    width: 110%;
                    height: 257px;
                    border-radius: 9px;
                    box-shadow: 9.08333px 9.08333px 18.1667px rgba(0, 0, 0, 0.25);
                    margin-right: -45px;
                }
            }
        }
    }

    @media(max-width: 1240px) {
        > div {
            justify-content: center;
        }

        .tns-outer {
            width: 88%;
        }

        .testimony-item {
            > div {
                width: 80%;
            }
        }
    }

    @media(max-width: 1100px) {
        .testimony-item {
            > div {
                width: 80%;

                .testimony-image {
                    img {
                        width: 370px;
                        height: 220px;
                    }
                }
            }
        }
    }

    @media(max-width: $limit_mobile) {
        .tns-outer {
            width: 100%;
        }

        .testimony-item {
            margin-left: 20px;
            margin-top: 100px;

            > div {
                width: 100%;
                flex-direction: column-reverse;
                margin-left: 0;
                padding: 0 10px 30px;
                justify-content: flex-end;

                .testimony-text {
                    width: 100%;
                    padding: 0;
                    text-align: center;

                    .quote-icon {
                        position: inherit;
                        margin-top: 35px;
                        margin-top: 25px;
                        display: flex;
                        justify-content: center;
    
                        svg {
                            width: 62px;
                        }
                    }

                    .testimony-quote {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 29px;
                    }
                    .testimony-name {
                        font-size: 14px;
                        line-height: 35px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }

                .testimony-image {
                    width: 90%;
                    align-self: center;
                    margin-top: -100px;

                    img {
                        width: 100%;
                        margin-right: 0;
                        height: 260px;
                    }
                }
            }
        }
    }
}