.segments {

	padding-bottom: 146px;
	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;
	position: relative;
	overflow: hidden;

	&:not(.has-decoration) {

		.decoration-top,
		.decoration-bottom {

			display: none;
		}
	}

	.decoration-top {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		
		svg {

			width: 100%;
			height: auto;
		}

		&.mobile-only {

			width: 20.66 * $columnSizeMobile;
			left: 50%;
		}
	}

	.decoration-bottom {

		position: absolute;
		transform: rotate(-8.45deg) translateX(-2.26%);
    	bottom: 12%;
		left: 0;
		width: 40 * $columnSizeDesktop;
		
		svg {

			width: 100%;
			height: auto;
		}
	}

	h2 {

		position: relative;
		z-index: 1;
		margin-left: 2 * $columnSizeDesktop;
		margin-right: 2 * $columnSizeDesktop;
		width: 20.9 * $columnSizeDesktop;
		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: 65px;
		margin-top: 60px;
		margin-bottom: 40px;
	}


	&:not(.has-title) {

		h2 {
			display: none;
		}
	}

	&.has-title {

		.carousel {

			.tns-item {

				.item {

					padding-top: 58px;
				}
			}
		}
	}

	.carousel {
		
		position: relative;
		z-index: 1;

		.tns-item {

			.item {

				position: relative;
				padding-top: 100px;
				padding-bottom: 58px;
				padding-left: 2 * $columnSizeDesktop;
				padding-right: 2 * $columnSizeDesktop;
				transition: background-color $timeToAnimate;

				h4 {

					font-family: "Montserrat", sans-serif;
					width: 6 * $columnSizeDesktop;
					font-size: 25px;
					font-style: normal;
					font-weight: 500;
					line-height: 35px;
					margin-bottom: 30px;
					transition: color $timeToAnimate;
				}

				.box-image {
					
					position: relative;
					
					img {

						width: 100%;
						height: 16.7 * $columnSizeDesktop;
						object-fit: cover;
						object-position: center;
						border-radius: 10px;
						transition: border-radius $timeToAnimate, box-shadow $timeToAnimate;
					}

					.btn-icon {

						position: absolute;
						top: 0;
						right: 28px;
						transform: translateY(-50%);
					}
				}

				.link-abs {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}

				&:active {

					h4 {

						color: var(--text-light);
					}

					.box-image {

						img {

							border-radius: 0px;
							box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
						}

						.btn-icon {

							border-color: var(--medium-red-color);

							svg {

								path {

									stroke: var(--medium-red-color);
								}
							}

							&:before {

								opacity: 0;
							}
						}
					}	
				}
			}

			&:nth-child(1) {

				.item {

					&:active {

						background-color: var(--extralight-red-color);
					}
				}
			}

			&:nth-child(2) {

				padding-top: 100px;

				.item {

					&:active {

						background-color: var(--medium-red-color);
					}
				}
			}

			&:nth-child(3) {

				padding-top: 200px;

				.item {

					&:active {

						background-color: var(--dark-red-color);
					}
				}
			}
		}
	}

	@media(min-width: $limit_mobile) {

		.carousel {

			.tns-item {
				
				.item {

					&:hover {

						h4 {

							color: var(--text-light);
						}

						.box-image {

							img {

								border-radius: 0px;
								box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
							}

							.btn-icon {

								border-color: var(--medium-red-color);

								svg {

									path {

										stroke: var(--medium-red-color);
									}
								}

								&:before {

									opacity: 0;
								}
							}
						}
					}
				}

				&:nth-child(1) {

					.item {

						&:hover {

							background-color: var(--extralight-red-color);
						}
					}
				}

				&:nth-child(2) {

					padding-top: 100px;

					.item {

						&:hover {

							background-color: var(--medium-red-color);
						}
					}
				}

				&:nth-child(3) {

					padding-top: 200px;

					.item {

						&:hover {

							background-color: var(--dark-red-color);
						}
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 114px;
		padding-left: 0.5 * $columnSizeMobile;
		padding-right: 0;
		padding-bottom: 15%;

		.decoration-bottom {

			margin-top: -20px;
			position: relative;
			bottom: unset;
			left: unset;
			width: 53 * $columnSizeMobile;
			transform: rotate(-8.45deg) translateX(-13%);
		}

		h2 {

			margin-right: $columnSizeMobile;
			margin-left: $columnSizeMobile;
			margin-top: 0;
			margin-bottom: 13px;
			font-size: 30px;
			line-height: 35px;
			width: initial;
		}

		.carousel {

			.tns-item {

				.item {	

					padding-top: 0px;
					margin-left: 0.25 * $columnSizeMobile;
					margin-right: 0.25 * $columnSizeMobile;
					padding-left: 0.25 * $columnSizeMobile;
					padding-right: 0.25 * $columnSizeMobile;
					padding-bottom: 20px;

					h4 {

						width: 100%;	
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
						margin-bottom: 21px;
					}

					.box-image {

						img {

							height: min(21 * #{$columnSizeMobile}, 495px);
						}

						.btn-icon {

							width: 28.66px;
							height: 28.66px;

							svg {

								width: 12px;
								height: 12px;
							}
						}
					}

					&:active {

						background-color: transparent !important;

						h4 {

							color: var(--text-dark);
						}
					}
				}

				&:nth-child(2),
				&:nth-child(3) {

					padding-top: 0;
				}
			}
		}
	}
}