.card-one-column {

	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;
	padding-top: 55px;
	padding-bottom: 120px;

	.columns {

		display: flex;

		.text-content {

			display: flex;
			flex-direction: column;
			padding-top: 95px;
			padding-left: 2 * $columnSizeDesktop;
			padding-right: 4 * $columnSizeDesktop;
			width: 55%;

			h2 {

				font-size: 50px;
				font-style: normal;
				font-weight: 600;
				line-height: 65px;
			}

			p {
				margin-top: 50px;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.02em;
			}
		}

		.decoration {

			display: none;
		}

		.image-content {

			width: 45%;

			img {

				border-radius: 10px;
				width: 100%;
				height: min(17.7 * #{$columnSizeDesktop}, 511px);
				object-fit: cover;
				object-position: center;
			}
		}
	}

	&.only-text {

		padding-bottom: 178px;
		padding-top: 95px;
		position: relative;

		.columns {

			.text-content {

				width: 100%;
				flex-direction: row;
				padding-top: 0;
				padding-right: 2 * $columnSizeDesktop;

				h2 {

					width: 50%;
				}

				.decoration {

					display: block;
					width: 34 * $columnSizeDesktop;
					position: absolute;
					bottom: 0;
					left: 0;

					svg {

						width: 100%;
					}
				}

				.markdown-content {

					margin-top: 82px;
					width: 50%;
				}
			}

			.image-content {

				display: none;
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 30px 0px;

		.columns {

			flex-direction: column;

			.text-content {

				padding-top: 0;
				padding-left: 0;
				padding-right: 0;
				width: 100%;

				h2 {

					padding-left: 2 * $columnSizeMobile;
					padding-right: 2 * $columnSizeMobile;
					font-size: 30px;
					font-style: normal;
					font-weight: 600;
					line-height: 35px;
				}

				p {

					margin-top: 25px;
					padding-left: 2 * $columnSizeMobile;
					padding-right: 2 * $columnSizeMobile;
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
				}
			}

			.image-content {

				margin-top: 10px;
				width: 100%;
				padding-left: $columnSizeMobile;
				padding-right: $columnSizeMobile;

				img {

					height: min(23 * #{$columnSizeMobile}, 511px);
				}
			}
		}

		&.only-text {
			
			padding-bottom: 30px;
			padding-top: 55px;

			.columns {

				.text-content {

					flex-direction: column;
					padding-right: 0;

					h2 {

						width: 100%;
					}

					.decoration {

						position: relative;
						width: 100%;
						margin-top: -6%;
						margin-bottom: -5%;
					}

					p {

						margin-top: 13px;
						width: 100%;
					}

					.markdown-content {

						margin-top: 0px;
						width: 100%;
					}
				}				
			}			
		}
	}
}