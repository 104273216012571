footer {
	
	.footer-container {

		width: 46 * $columnSizeDesktop;
		margin-right: auto;
		margin-left: auto;
		background-color: var(--bg-medium);
		padding: min(2 * #{$columnSizeDesktop}, 65px);
		position: relative;
		border-radius: 10px 10px 0px 0px;

		.scroll-top {

			position: absolute;
			top: -30px;
			left: 50%;
			transform: translateX(-50%);
		
			.line {

				margin-right: auto;
				margin-left: auto;
				background-color: var(--medium-red-color);
				width: 1.5px;
				height: 140px;		
			}

			button {

				cursor: pointer;
				margin-right: auto;
				margin-left: auto;
				background-color: transparent;
				padding: 0;
				border: none;
				margin-top: 14px;
				display: block;
				color: var(--medium-red-color);
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				transition: transform $timeToColor;

				&:active {

					transform: scale(1.1);
				}
			}			
		}

		.social-media {

			display: flex;
			align-items: center;
			
			a {

				margin-left: 17px;
			}
		}

		.first-row {

			display: flex;
			align-items: center;
			justify-content: space-between;

			.logo {

				a {

					display: block;
					line-height: 0;
					width: 230px;
					height: 41px;

					.image-logo {

						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}

		.second-row {

			margin-top: 120px;
			display: flex;
			justify-content: space-between;

			.unit-info {

				.address,
				.phone {

					transition: color $timeToColor;
					display: block;
					font-family: 'Roboto', sans-serif;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					width: max-content;

					&:active {

						color: var(--medium-red-color);
					}
				}

				.address {

					text-decoration: underline;

					&:not(:last-child) {
				
						margin-bottom: 7px;
					}
				}
			}

			.links {

				display: flex;
				flex: 1;
				justify-content: space-evenly;

				.column-1,
				.column-2,
				.column-3 {
				
					a {

						transition: color $timeToColor;
						display: block;
						font-size: 15px;
						font-style: normal;
						font-weight: 500;
						line-height: 18px;
						width: max-content;
						
						&:active {

							color: var(--medium-red-color);
						}

						&:not(:last-child) {

							margin-bottom: 21px;
						}
					}
				}

				&.is-institutional {

					justify-content: flex-start;

					.column-1,
					.column-2,
					.column-3 {

						margin-right: auto;
					}
				}
			}

			.policy-and-cookies {

				a, p {

					color: var(--dark-gray-color);
					font-family: 'Roboto', sans-serif;
					font-size: 12px;
					font-weight: 400;
					line-height: 14px;
				}

				a {	

					transition: color $timeToColor;
					display: block;
					width: max-content;
					text-decoration: underline;

					&:active {

						color: var(--medium-red-color);
					}

					&:not(last-child) {
						
						margin-bottom: 16px;
					}
				}

				p {

					&:not(first-child) {

						margin-top: 42px;
					}
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.footer-container {

			.scroll-top {

				button {

					&:hover {

						transform: scale(1.1);
					}
				}
			}

			.second-row {

				.unit-info .address,
				.unit-info .phone,
				.links .column-1 a,
				.links .column-2 a,
				.links .column-3 a,
				.policy-and-cookies a {

					&:hover {

						color: var(--medium-red-color);
					}
				}
			}
		}
	}

	@media(max-width: 1300px) {

		.footer-container {

			padding-bottom: 106px;
		}
	}

	@media(max-width: $limit_mobile) {

		.footer-container {

			width: 23 * $columnSizeMobile;
			padding-top: 0px;
			padding-left: $columnSizeMobile;
			padding-right: $columnSizeMobile;
			
			.scroll-top {

				position: relative;
				top: unset;
				left: unset;
				transform: translateY(-20px);
				margin-top: -20px;
				margin-bottom: 26px;

				.line {

					height: 40px;
				}

				a {
					margin-left: auto;
					margin-right: auto;
					width: max-content;
					margin-top: 5px;
					font-size: 14px;
					font-weight: 600;
					line-height: 17px;
				}
			}

			.second-row {

				margin-top: 0px;
				flex-direction: column;

				.unit-info {

					margin-bottom: 46px;
				}

				.links {

					flex-direction: column;

					.column-1,
					.column-2,
					.column-3 {

						a {
							margin-bottom: 19px;
						}
					}
				}

				.social-media {

					margin-top: 30px;
					margin-bottom: 38px;

					a {

						margin-left: 14px;

						&:first-child {

							margin-left: 0px;
						}
					}
				}

				.policy-and-cookies {

					a, p {

						font-size: 12px;
						font-weight: 400;
						line-height: 14px;
					}

					a {

						margin-bottom: 27px;
					}

					p {
						margin-top: 0px !important;
					}
				}
			}
		}
	}
}