.title-and-description {

	padding-top: 118px;
	padding-bottom: 80px;
	padding-left: 4 * $columnSizeDesktop;
	padding-right: 4 * $columnSizeDesktop;

	h2 {

		font-size: 50px;
		font-weight: 600;
		line-height: 65px;
	}

	.markdown-content {

		margin-top: 53px;
	}

	@media(max-width: $limit_mobile) {

		padding-top: 41px;
		padding-bottom: 20px;
		padding-left: 2 * $columnSizeMobile;
		padding-right: 2 * $columnSizeMobile;

		h2 {
			
			font-size: 30px;
			line-height: 35px;
		}

		.markdown-content {

			margin-top: 23px;
		}
	}
}