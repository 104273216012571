.media-gallery {

	padding: 40px 0;
	
	.block-header {

		max-width: min(21 * #{$columnSizeDesktop}, 604px);
		margin-left: 4 * $columnSizeDesktop;
		margin-right: 4 * $columnSizeDesktop;
		margin-bottom: 55px;
		
		h2 {

			font-size: 50px;
			font-weight: 600;
			line-height: 65px;
		}

		p {

			font-size: 18px;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: 0.02em;
			margin-top: 36px;
		}
	}

	.main {

		.tns-item {

			padding-left: 2 * $columnSizeDesktop;
			padding-right: 2 * $columnSizeDesktop;

			.item {

				display: flex;
				height: 100%;
				flex-direction: column;
				justify-content: flex-end;

				.legend {

					text-transform: uppercase;
					margin-bottom: 19px;
					font-size: 16px;
					font-weight: 400;
					line-height: 35px;
					letter-spacing: 0.02em;
				}

				.banner {

					position: relative;
					overflow: hidden;
					border-radius: 10px;

					.mask {

						pointer-events: none;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: linear-gradient(105.72deg, rgba(168, 24, 21, 0.56) -0.81%, rgba(37, 49, 60, 0) 100%);
					}

					& > img {

						width: 100%;
						max-height: 682px;
						height: 23.6 * $columnSizeDesktop;
						border-radius: 10px;
						object-fit: cover;
						display: block;
					}
				

					.btn-open-video {

						border-radius: 10px;
						overflow: hidden;	
						width: 100%;
						max-height: 682px;
						height: 23.6 * $columnSizeDesktop;
						position: relative;
						cursor: pointer;

						
						img {

							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
							transition: transform $timeToAnimate;
						}

						.play {

							position: absolute;
							top: 50%;
							left: 50%;
							z-index: 1;
							transform: translate(-50%, -50%);

							svg {

								width: 67px;
								height: auto;

								path {

									transition: stroke $timeToAnimate;
								}
							}
						}

						&:active {

							img {

								transform: scale(1.02);
							}

							.play {

								svg {

									path {

										stroke: var(--medium-red-color);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.secondary-box {

		margin-top: 36px;
		padding-right: 2 * $columnSizeDesktop; 
		padding-left: 2 * $columnSizeDesktop;
		margin-right: 2 * $columnSizeDesktop; 
		margin-left: 2 * $columnSizeDesktop;
		position: relative;

		.tns-item {

			padding-left: 0.5 * $columnSizeDesktop;
			padding-right: 0.5 * $columnSizeDesktop;

			img {

				cursor: pointer;
				border-radius: 10px;
				width: 100%;
				height: min(7.4 * #{$columnSizeDesktop}, 215px);
				object-fit: cover;
			}
		}

		.btn-previous {

			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			svg {

				transform: scaleX(-1);
			}
		}

		.btn-next {
			
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.main {

			.tns-item {

				.item {

					.btn-open-video {

						&:hover {

							img {

								transform: scale(1.02);
							}

							.play {

								svg {

									path {

										stroke: var(--medium-red-color);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: 1200px) {

		.secondary-box {

			.btn-previous {

				left: -1 * $columnSizeDesktop;
			}

			.btn-next {

				right: -1 * $columnSizeDesktop;
			}
		}
	}

	@media(max-width: $limit_mobile) {

		.block-header {

			max-width: initial;
			margin-left: 2 * $columnSizeMobile;
			margin-right: 2 * $columnSizeMobile;
			
			h2 {

				font-size: 30px;
				line-height: 35px;
			}

			p {

				font-size: 14px;
				line-height: 21px;
				margin-top: 20px;
			}
		}

		.main {

			padding-left: 0.5 * $columnSizeMobile;

			.tns-item {

				padding-left: 0.5 * $columnSizeMobile;
				padding-right: 0.5 * $columnSizeMobile;

				.item {

					.legend {

						padding-left: $columnSizeMobile;
						padding-rightt: $columnSizeMobile;
						margin-bottom: 7px;
						font-size: 12px;
						line-height: 20px;
					}

					.banner {

						& > img {

							max-height: 400px;
							height: 20.7 * $columnSizeMobile;
						}
					
						.btn-open-video {

							max-height: 400px;
							height: 20.7 * $columnSizeMobile;
							
							.play {

								svg {

									width: 50px;
								}
							}
						}
					}
				}
			}
		}

		.secondary-box {

			display: none;
		}
	}
}