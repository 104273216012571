.animated-numbers {

    padding-top: 100px;

	&.bg-image {

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-top: 120px;
		padding-bottom: 120px;
	}

	.decoration {
		
		margin-top: 40px;
		width: 30%;
		height: auto;

		svg {

			display: block;
			width: 100%;
			height: auto;
		}
	}

    .box-carousel {

		position: relative;

		.carousel {
				
			.tns-item {

				padding: $columnSizeDesktop;
				transition: opacity $timeToColor;

				&:not(.tns-slide-active) {
					opacity: 0;
				}

				.item {

					height: 100%;
                    box-shadow: 5px 10px 30px rgba(80, 68, 68, 0.3);
                    background-color: #fff;
					padding: 60px;
					border-radius: 10px;
					display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
					flex-direction: column;
                    color: #504444;

					img {

						width: max(40%, 100px);
						height: auto;
						aspect-ratio: 1/1;
						object-fit: cover;
						border-radius: 50%;
					}

					.number {

						scroll-behavior: smooth;
						margin-top: 50px;
						width: 100%;
						margin-bottom: 30px;
						max-height: 70px;
						overflow: hidden;
					}

                    .number p {
                        
                        font-size: 70px;
						height: 70px;
                        font-weight: 600;
                        line-height: 1;
						display: flex;
						width: 100%;
						align-items: center;
						justify-content: center;
                    }

					.description {

						font-size: 24px;
						line-height: 1.35;
					}
				}
			}
		}

		.btn-icon {

			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-100%, -50%) scaleX(-1);

			&.btn-next {

				left: unset;
				right: 0;
				transform: translate(100%, -50%) scaleX(1);
			}
		}
    }

	@media(max-width: 1200px) {

		.box-carousel .carousel {
				
			.tns-item {

				.item {

					padding: 30px;

					.number {

						margin-top: 10px;
						margin-bottom: 10px;
					}

                    .number p {
                        
                        font-size: 42px;
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 50px;

		&.bg-image {

			padding-top: 80px;
			padding-bottom: 80px;
		}

		.decoration {

			width: 100%;
		}

		.box-carousel {

			.btn-icon {

				transform: translate(0, -50%) scaleX(-1);

				&.btn-next {

					transform: translate(0, -50%) scaleX(1);
				}
			}

			.carousel {

				.tns-item {

					.item {
	
						padding: 40px;
	
						.number {
	
							margin-top: 30px;
							margin-bottom: 20px;
						}
	
						.number p {
							
							font-size: 64px;
						}
					}
				}
			}
		}
	}
}