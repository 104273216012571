.carousel-one-column {

	position: relative;

	.carousel {

        .carousel_items:not(:first-child) {
            display: none;
        }

		.item {

			min-height: min(682px, 23.6 * #{$columnSizeDesktop});
			padding-top: 112px;
			padding-bottom: 112px;
			padding-left: 4 * $columnSizeDesktop;
			padding-right: 45%;
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			img,
			video {

				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.mask {

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(105.72deg, rgba(37, 49, 60, 0.8) -0.81%, rgba(37, 49, 60, 0) 100%);
			}

			.texts {

				position: relative;
				z-index: 1;

				h4 {

					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.02em;
					color: var(--text-light);
					margin-bottom: 5px;
				}

				h2 {

					font-size: 50px;
					font-style: normal;
					font-weight: 600;
					line-height: 65px;
					letter-spacing: 0em;
					color: var(--text-light);
				}

				p {
					margin-top: 35px;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px;
					color: var(--text-light);
				}
			}

			.buttons {

				position: relative;
				z-index: 1;
				margin-top: 22px;
				display: flex;
				flex-wrap: wrap;

				.btn {
						
					margin-top: 11px;

					&:not(:last-child) {

						margin-right: 14px;
					}
				}
			}
		}
	}

	.navigation {

		display: flex;
		position: absolute;
		left: 4 * $columnSizeDesktop;
		bottom: 40px;

		.btn-icon {

			&.btn-previous {

				transform: scaleX(-1);
			}

			&:not(:last-child) {

				margin-right: 16px;
			}
		}
	}

	.tns-nav {

		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		
		button {

			height: 9px;
			width: 9px;
			padding: 0;
			transition: background-color $timeToColor;
			background-color: var(--text-light);
			border: none;
			border-radius: 50%;

			&:not(:last-child) {

				margin-right: 5px;
			}
			
			&.tns-nav-active,
			&:active {

				background-color: var(--medium-red-color);
			}
		}
	}

	.scroll-bottom {

		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);

		.button-scroll-bottom {

			transition: color $timeToColor;
			color: var(--text-light);
			width: max-content;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
			cursor: pointer;

			&:active {

				color: var(--medium-red-color);
			}
		}

		.line {

			width: 3px;
			height: 57px;
			margin-left: auto;
			margin-right: auto;
			background-color: var(--text-light);
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.scroll-bottom {

			.button-scroll-bottom {

				&:hover {

					color: var(--medium-red-color);
				}
			}
		}
	}


	@media(max-width: 1200px) {

		.carousel {

			.item {

				padding-right: 35%;
			}
		}
	}

	@media(max-width: $limit_mobile) {

		.carousel {

			.item {

				padding-top: 47px;
				padding-bottom: 45px;
				padding-left: 2 * $columnSizeMobile;
				padding-right: 2 * $columnSizeMobile;

				.texts {

					h4 {

						font-size: 14px;
						line-height: 24px;
						margin-bottom: 5px;
					}

					h2 {

						font-size: 30px;
						line-height: 35px;
					}

					p {
						
						margin-top: 12px;
						font-size: 14px;
						line-height: 21px;
					}
				}

				.buttons {

					margin-top: 6px;
				}
			}
		}
	}
}