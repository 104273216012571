body {

	// Featured colors
	
	--dark-red-color: #A81815;
	--medium-red-color: #CC1316;
	--light-red-color: #DA0001;
	--extralight-red-color: #DF3813;

	--pink-color: #FFE3DF;

	--black-color: #504444;
	
	--dark-gray-color: #6A6A6A;
	--gray-color: #D8D8D8;
	--light-gray-color: #F6F6F5;

	--white-color: #FFFFFF;

	// Default text colors

	--text-light: var(--white-color);
	--text-medium: #726A6A;
	--text-dark: var(--black-color);

	// Background colors

	--bg-gradient: linear-gradient(90deg, var(--dark-red-color) 0%, var(--light-red-color) 100%);
	--bg-dark: var(--black-color);
	--bg-medium: var(--light-gray-color);
	--bg-light: var(--white-color);
}

$timeToColor: 0.25s ease-in-out;
$timeToAnimate: 0.5s ease-in-out;

$columnSizeDesktop: 2vw;
$columnSizeMobile: 4vw;

$limit_mobile: 940px;