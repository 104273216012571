.quote {

	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;

	.columns {

		display: flex;
		background-color: var(--bg-medium);
		border-radius: 10px;
		overflow: hidden;

		.image-content {

			width: 43%;

			img {

				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.text-content {

			width: 57%;
			padding: 65px (2 * $columnSizeDesktop) 65px (3 * $columnSizeDesktop);

			svg {

				display: block;
				height: auto;
				width: min(4 * #{$columnSizeDesktop}, 115px);
				margin-bottom: 34px;
			}

			.citation {

				font-size: 34px;
				font-style: normal;
				font-weight: 300;
				line-height: 48px;
				letter-spacing: 0.02em;
				text-align: left;
				margin-bottom: 34px;
			}

			.author {

				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.02em;
				color: var(--medium-red-color);
			}
		}
	}

	&.only-text {

		padding-left: 4 * $columnSizeDesktop;
		padding-right: 4 * $columnSizeDesktop;

		.columns {

			background-color: transparent;
			
			.image-content {

				display: none;
			}

			.text-content {

				position: relative;
				width: 100%;
				padding: 50px 0px 50px min(5 * #{$columnSizeDesktop}, #{$columnSizeDesktop} + 115px);
				
				svg {

					position: absolute;
					margin-bottom: 0;
					top: 0;
					left: 0;
				}

				.citation {

					margin-bottom: 25px;
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 20px $columnSizeMobile;

		.columns {

			flex-direction: column;

			.image-content {

				width: 100%;

				img {

					height: 17 * $columnSizeMobile;
					max-height: 400px;
				}
			}

			.text-content {

				width: 100%;
				padding: (1.5 * $columnSizeMobile) $columnSizeMobile;

				svg {

					width: min(5 * #{$columnSizeMobile}, 115px);
					margin-bottom: 20px;
				}

				.citation {

					margin-left: 2 * $columnSizeDesktop;
					font-size: 23px;
					line-height: 34px;
				}

				.author {

					font-size: 12px;
					line-height: 20px;
				}
			}
		}

		&.only-text {

			padding-left: 2 * $columnSizeMobile;
			padding-right: 2 * $columnSizeMobile;

			.columns {

				.text-content {

					padding: 0;

					svg {

						position: relative;
						top: unset;
						left: unset;
						width: min(4 * #{$columnSizeMobile}, 85px);
						margin-bottom: 20px;
					}

					.citation {

						margin-bottom: 25px;
						margin-left: 0;
					}
				}
			}
		}
	}
}