.square-image-video {
  
  padding-left: 3 * $columnSizeDesktop;
  padding-right: 3 * $columnSizeDesktop;
  padding-bottom: 91px;
  padding-top: 41px;

  .grid {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4vw;

    &.multiple-items {
      .square-item {
        height: 35vh;
      }
    }

    .square-item {

      position: relative;
      aspect-ratio: 1 / 1;
      height: 80vh;

      img {

        width: 100%;
        border-radius: 10px;
        height: 100%;
        object-fit: cover;
        object-position: center;
        &.thumb-video {
          object-fit: fill;
        }
      }

      &.has-modal-video {
        cursor: pointer;
      }

      .mask {
        display: none;
        position: absolute;
        border-radius: 10px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 2, 3, 0.28);
      }

      .play {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);

        svg {

          width: 67px;
          height: auto;

          path {

            transition: stroke $timeToAnimate;
          }
        }
      }
    }

    .modal-video {
      display: flex;
      position: fixed;
      top: 0;
      z-index: 9999;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 60px;
      opacity: 0;
      transition: 0.5s;
      visibility: hidden;

      &.show {
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
      }

      .close-button-modal {
        cursor: pointer;
      }

      .square-video {

        iframe {
          width: 70vw;
          height: 70vh;
        }
      }
    }
  }

  @media(max-width: $limit_mobile) {

    padding-top: 10px;
    padding-bottom: 26px;
    padding-left: $columnSizeMobile;
    padding-right: $columnSizeMobile;

    .grid {

      flex-direction: column;

      .square-item {
        height: 35vh;

        &:not(:last-child) {

          margin-bottom: 14px;
        }
      }

      .modal-video {

        .square-video {

          iframe {
            width: 90vw;
            height: 30vh;
          }
        }
      }
    }
  }
}