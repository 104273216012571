.select2 {
    width: 100% !important;
}

.select2-container {
    z-index: 11;
}
/* 
.select2-container--open {
    top: 768.281px !important;
} */

.select2-container--below {
    position: absolute;
    top: 0 !important;
}

.select2.select2-container .select2-selection {
    width: 100%;
    height: 20px;
    padding-bottom: 23px;
    border: none;
    border-bottom: 1.2px solid var(--black-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: transparent;
    color: var(--black-color);
    outline: none;
    transition: all 0.1s ease-in-out;
}

.select2-selection--single {
    border-radius: 0 !important;
}

.select2-selection .select2-selection--single {
    padding-bottom: 20px !important;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: 1px solid #aaa;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-bottom: 1px;
}


.select2-selection__arrow b {
    display: none;
}


.select2-selection__arrow a.select2-a {
    /* background-image: url("/static/images/icon-chevron-right.svg") no-repeat center center; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -25px;
    margin-top: 16px;
    transform: rotate(90deg);
    transition: transform 0.5s ease;
}

.select2-selection__arrow a.select2-a:focus {
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: #28334a;   
}

@media screen and (max-width: 940px) {
    .select2-results__option {
        font-size: 12px;
    }
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #d8d8d8;
    color: #28334a;
}

.select2 .select2-container .select2-selection__arrow a.select2-a {
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
}