.callout {

	padding: 100px 0px;
	
	.callout-container {

		width: 44 * $columnSizeDesktop;
		margin-left: 2 * $columnSizeDesktop;
		padding-top: 42px;
		padding-bottom: 42px;
		background: var(--bg-gradient);
		display: flex;
		border-radius: 10px;
		position: relative;

		.text-content {

			width: 23 * $columnSizeDesktop;
			padding: 38px (2 * $columnSizeDesktop);
		
			h3 {

				font-size: 50px;
				font-weight: 600;
				line-height: 65px;
				letter-spacing: -0.01em;
				color: var(--text-light);
				margin-bottom: 16px;
			}

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.02em;
				color: var(--text-light);
				margin-bottom: 34px;
			}

			.btn-icon {

				&:after {

					content: '';
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: calc(100% + (2 * #{$columnSizeDesktop}));
					z-index: 1;
				}
			}
		}

		.image-content {

			height: 283px;
			flex-grow: 1;
			align-self: flex-start;
			position: relative;

			img {

				box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% + (2 * #{$columnSizeDesktop}));
				height: 100%;
				object-fit: cover;
			}
		}
	}

	@media(max-width: 1200px) {

		.callout-container {

			.text-content {

				width: 28 * $columnSizeDesktop;
			}
		}
	}

	@media(max-width: $limit_mobile) {
		
		padding-bottom: 71px;

		.callout-container {

			width: 23 * $columnSizeMobile;
			flex-direction: column;
			padding-top: 0px;
			padding-left: $columnSizeMobile;
			padding-right: $columnSizeMobile;

			.image-content {

				margin-top: -72px;
				width: 100%;
				max-height: 320px;
				height: 16.82 * $columnSizeMobile;
				flex-grow: initial;

				img {

					position: relative;
					top: unset;
					left: unset;
					width: 100%;
				}
			}

			.text-content {

				padding: 0;
				order: 1;
				width: 100%;

				h3 {

					font-size: 30px;
					font-weight: 600;
					line-height: 35px;
					margin-top: 26px;
					margin-bottom: 11px;
				}

				p {
					
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0.02em;
					margin-bottom: 20px;
				}
			}
		}
	}
}