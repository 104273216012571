.card-two-columns {

	padding-top: 150px;
	padding-bottom: 60px;
	padding-left: 6 * $columnSizeDesktop;
	padding-right: 6 * $columnSizeDesktop;
	overflow: hidden;

	.carousel {

		display: flex;
		flex-wrap: wrap;
		
		.tns-item {

			width: 50%;
			padding-top: min(2.25 * #{$columnSizeDesktop}, 63.5px);
			padding-left: $columnSizeDesktop;
			padding-right: $columnSizeDesktop;
			padding-bottom: 40px;

			.item {

				border: 1.2px solid var(--medium-red-color);
				padding: 11px;
				border-radius: 10px;
				position: relative;
				height: 100%;

				.item-body {

					height: 100%;
					box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
					border-radius: 10px;
					padding-top: min(2.25 * #{$columnSizeDesktop}, 63.5px);	
					padding-left: 50px;
					padding-right: 50px;
					padding-bottom: 75px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.image-box {

						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, -50%);
						width: min(4.5 * #{$columnSizeDesktop}, 127px);
						height: min(4.5 * #{$columnSizeDesktop}, 127px);
						background-color: var(--bg-medium);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;

						img {

							width: min(2 * #{$columnSizeDesktop}, 60px);
							height: min(2 * #{$columnSizeDesktop}, 60px);
							object-fit: contain;
							object-position: center;
						}
					}

					h4 {

						font-family: "Montserrat", sans-serif;
						font-size: 25px;
						font-style: normal;
						font-weight: 500;
						line-height: 35px;
						letter-spacing: 0em;
						text-align: left;
						color: var(--medium-red-color);
						margin-bottom: 33px;
					}

					p {
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px;
						letter-spacing: 0.02em;
						text-align: left;
					}

					a {

						margin-top: 33px;
					}
				}
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 30px 0;
		padding-left: 0.25 * $columnSizeMobile;

		.tns-ovh {

			overflow: visible;
		}

		.carousel {
			
			.tns-item {

				padding-top: min(2.75 * #{$columnSizeMobile}, 42px);
				padding-left: 0.75 * $columnSizeMobile;
				padding-right: 0.75 * $columnSizeMobile;
				padding-bottom: 40px;

				.item {

					padding: 7px;
					
					.item-body {

						box-shadow: 5px 10px 30px rgba(80, 68, 68, 0.3);
						padding-top: min(2.75 * #{$columnSizeMobile}, 42px);	
						padding-left: 20px;
						padding-right: 20px;
						padding-bottom: 22px;

						.image-box {

							position: absolute;
							top: 0;
							left: 50%;
							transform: translate(-50%, -50%);
							width: min(5.5 * #{$columnSizeMobile}, 84px);
							height: min(5.5 * #{$columnSizeMobile}, 84px);

							img {

								width: min(2.5 * #{$columnSizeMobile}, 40px);
								height: min(2.5 * #{$columnSizeMobile}, 40px);
								object-fit: contain;
								object-position: center;
							}
						}

						h4 {

							font-size: 18px;
							line-height: 24px;
							margin-bottom: 12px;
						}

						p {

							font-size: 14px;
							line-height: 21px;
						}

						a {

							margin-top: 14px;
						}
					}
				}
			}
		}
	}
}