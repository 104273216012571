.floating-buttons {
	
	z-index: 99;
	position: fixed;
	bottom: 0;
	left: 0;
	padding-left: 2 * $columnSizeDesktop;
	padding-right: 2 * $columnSizeDesktop;
	width: 100%;
	display: flex;
	justify-content: space-between;
	pointer-events: none;

	.btn {

		pointer-events: auto;
		display: none;
		border-radius: 30px 30px 0 0;
		border-bottom: none;
		align-items: center;
		
		&:before {

			border-radius: 30px 30px 0 0;
		}
	}

	.chat {

		pointer-events: auto;
		display: flex;
		order: 1;
		margin-left: auto;
		padding-left: 15px;
		padding-right: 15px;

		svg {

			display: block;

			path {	

				transition: stroke $timeToColor;
			}
		}

		&:last-child {
		  	
		  	margin-left: initial;
		}

		&:active {

			svg {

				path {

					stroke: var(--medium-red-color);
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.chat {

			&:hover {

				svg {

					path {

						stroke: var(--medium-red-color);
					}
				}
			}
		}
	}

	@media(max-width: 1300px) {

		.btn {

			display: flex;
		}
	}

	@media(max-width: $limit_mobile) {

		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;

		.btn {

			padding-top: 10px;
			padding-bottom: 10px;
		}

		.chat {

			svg {

				width: 20px;
				height: auto;
			}
		}
	}
}