.background-image-and-content {

	padding-top: 237px;
	padding-bottom: 219px;
	padding-left: 4 * $columnSizeDesktop;
	position: relative;

	.box-image {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	
		.mask {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: matrix(-1, 0, 0, 1, 0, 0);
			background: linear-gradient(105.72deg, rgba(37, 49, 60, 0.6) -0.81%, rgba(37, 49, 60, 0.4) 49.45%, rgba(168, 24, 21, 0.2) 100%);
		}

		img {

			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-position: center;
			object-fit: cover;
		}
	}

	
	.column {

		position: relative;
		z-index: 1;
		width: 20 * $columnSizeDesktop;
		
		h2 {
			font-size: 50px;
			font-style: normal;
			font-weight: 600;
			line-height: 65px;
			color: var(--text-light);
		}

		p {

			color: var(--text-light);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 35px;
			margin-top: 37px;
		}
	}

	@media(max-width: 1300px) {

		.column {

			width: 30 * $columnSizeDesktop;
		}
	}

	@media(max-width: $limit_mobile) {

		padding: 0;

		.column {

			width: 100%;
			background-color: var(--bg-medium);
			padding: 40px (2 * $columnSizeMobile);

			h2 {

				color: var(--medium-red-color);
				font-size: 30px;
				font-style: normal;
				font-weight: 600;
				line-height: 35px;
			}

			p {

				margin-top: 22px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0.02em;
				color: var(--black-color);
			}
		}

		.box-image {

			position: relative;
			top: unset;
			left: unset;
			height: 97vw;
			width: 100%;
			max-height: 400px;
		}
	}
}