.carousel-three-columns {

	padding-top: 152px;
	padding-bottom: 102px;
	padding-left: 3.5 * $columnSizeDesktop;
	padding-right: 3.5 * $columnSizeDesktop;
	overflow: hidden;

	.intro {

		margin-bottom: 43px;
		margin-left: 0.5 * $columnSizeDesktop;
		margin-right: 0.5 * $columnSizeDesktop;

		h2 {

			width: 50%;
			font-size: 50px;
			font-style: normal;
			font-weight: 600;
			line-height: 65px;
			letter-spacing: 0em;
			text-align: left;
		}

		p {

			margin-top: 36px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 35px;
			letter-spacing: 0.02em;
			text-align: left;
		}
	}

	.box-carousel {

		position: relative;

		.carousel {
				
			.tns-item {

				padding-left: 0.5 * $columnSizeDesktop;
				padding-right: 0.5 * $columnSizeDesktop;
				padding-top: 40px;
				padding-bottom: 20px;
				transition: opacity $timeToColor;

				&:not(.tns-slide-active) {

					opacity: 0;
				}

				.item {

					height: 100%;
					border: 1.2px solid var(--medium-red-color);
					padding: 16px 10px;
					border-radius: 10px;
					display: flex;

					.item-body {

						box-shadow: 5px 10px 30px rgba(80, 68, 68, 0.3);
						background-color: var(--bg-light);
						border-radius: 10px;
						padding-bottom: 96px;
						flex: 1;
						
						.image-box {

							margin-top: -29px;
							height: 7.5 * $columnSizeDesktop;
							width: 100%;
							overflow: hidden;
							border-radius: 10px;
							background-color: var(--bg-medium);				
							
							img {

								height: 100%;
								width: 100%;					
								object-fit: cover;
								object-position: center;
							}

							&.icon {

								width: 6.3 * $columnSizeDesktop;
								height: 6.3 * $columnSizeDesktop;
								border-radius: 50%;
								margin-top: -55px;
								margin-left: auto;
								margin-right: auto;
								margin-bottom: 21px;
								display: flex;
								align-items: center;
								justify-content: center;

								img {

									width: 3 * $columnSizeDesktop;
									height: 3 * $columnSizeDesktop;
								}
							}
						}

						h4 {

							font-size: 30px;
							font-style: normal;
							font-weight: 600;
							line-height: 35px;
							letter-spacing: 0.02em;
							color: var(--medium-red-color);
							margin-left: 30px;
							margin-right: 30px;
							margin-top: 44px;
						}

						p {
							
							margin-left: 30px;
							margin-right: 30px;
							font-size: 17px;
							font-style: normal;
							font-weight: 300;
							line-height: 30px;
							letter-spacing: 0.02em;
							margin-top: 22px;
						}
					}
				}
			}
		}

		.btn-icon {

			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-100%, -50%) scaleX(-1);

			&.btn-next {

				left: unset;
				right: 0;
				transform: translate(100%, -50%) scaleX(1);
			}
		}
	}

	@media(max-width: 1350px) {

		.intro {
			
			h2 {

				width: 80%;
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 39px;
		padding-bottom: 46px;
		padding-left: 0.5 * $columnSizeMobile;
		padding-right: 0;

		.intro {

			margin-bottom: 4px;
			margin-left: 1.5 * $columnSizeMobile;
			margin-right: 2 * $columnSizeMobile;

			h2 {

				width: 100%;
				font-size: 30px;
				font-style: normal;
				font-weight: 600;
				line-height: 35px;
			}
			
			p {

				margin-top: 16px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0.02em;
			}
		}

		.box-carousel {

			.carousel {

				.tns-item {

					padding-left: 0.5 * $columnSizeMobile;
					padding-right: 0.5 * $columnSizeMobile;

					&:not(.tns-slide-active) {

						opacity: 1;
					}

					.item {

						padding: 9px 7px;

						.item-body {

							padding-bottom: 43px;

							.image-box {

								margin-top: -22px;
								height: 10 * $columnSizeMobile;

								&.icon {

									width: 93px;
									height: 93px;
									border-radius: 50%;
									margin-top: -44px;
									margin-left: auto;
									margin-right: auto;
									margin-bottom: 9px;

									img {

										width: 57px;
										height: 57px;
									}
								}
							}

							h4 {

								margin-top: 16px;
								font-size: 22px;
								line-height: 35px;
								margin-left: 1.5 * $columnSizeMobile;
								margin-right: 1.5 * $columnSizeMobile;
							}

							p {

								margin-left: 1.5 * $columnSizeMobile;
								margin-right: 1.5 * $columnSizeMobile;
								font-size: 14px;
								font-weight: 300;
								line-height: 26px;
								margin-top: 8px;
							}
						}
					}
				}
			}

			.tns-ovh {

				overflow: visible;
			}

			.btn-icon {

				display: none;
			}
		}
	}
}