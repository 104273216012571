
.contact-forms{
    z-index: 100;
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    .contact-icon{
        position: relative;
        
        width: 50px;
        margin-top: 9px;
        cursor: pointer;

        img:nth-child(1) {
            width: 100%;
        }

        img:nth-child(2) {
            position: absolute;
            width: 23px;
            height: 23px;
            right: -5px;
            top: -7px;
        }

    }
    
    .features{
        // display: none;
        // flex-direction: column;
        // align-items: flex-start;
        // background-color:white;
        // padding: 10px 25px 10px 15px;
        // border-radius: 8px;
        // margin-bottom: 10px;
        // box-shadow: 5px 10px 30px rgb(80 68 68 / 30%);

        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 6px 0px 6px;
        margin-bottom: -10px;

        .feature-item a{
            
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px 0;
            justify-content: flex-start;

            img{
                width: 45px;
            }
            
            p{
                color: #504444;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                font-size: 13px;
                text-transform: capitalize;
            }
        }
    }

    .feature {
        height: 60px !important;
    }

    .feature a{
        
        text-decoration: none;
        display: flex;
        margin: 10px 1px 10px;

        img{
            width: 50px;
        }

        p{
            color: #504444;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 13px;
            text-transform: capitalize;
        }
    }

    .features.open{
        display: flex !important;
        flex-direction: column;
        margin-bottom: 0px;
        

        .feature-item {
            height: 53px;
        }

    }
}
