.embed {

    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    gap: 50px;

    &.text-center {
        flex-direction: column;
    }

    &:not(.text-center) {
       
        .block-texts {

            width: calc(50% - 25px);
        }

        .box-iframe {

            width: calc(50% - 25px);
        }
    }

    &.text-right {

        .block-texts {
            order: 1;
        }
    }

    h2 {
    
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: 65px;
        letter-spacing: 0em;
    }

    p {
        
        margin-top: 37px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.02em;
    }

    .no-content,
    iframe {

        display: block;
        width: 100%;
    }

    iframe {

        aspect-ratio: 40 / 55;
    }

    @media(max-width: $limit_mobile + 1px) {

        padding-top: 60px;
        padding-bottom: 60px;
        gap: 30px;

        h2 {

            font-size: 30px;
            line-height: 35px;
        }

        p {

            font-size: 14px;
            line-height: 21px;
            margin-top: 20px;
        }

        &:not(.text-center) {

            flex-direction: column;

            .block-texts,
            .box-iframe {

                width: 100% !important;
            }

            .box-iframe {

                order: 1;
            }
        }
    }
}