.images-side-by-side {

	padding-left: 7 * $columnSizeDesktop;
	padding-right: 3 * $columnSizeDesktop;
	padding-bottom: 91px;
	padding-top: 41px;

	.grid {

		display: flex;

		.item {

			flex: 1;
			padding-left: $columnSizeDesktop;
			padding-right: $columnSizeDesktop;
			
			img {

				width: 100%;
				border-radius: 10px;
				height: auto;
				min-height: 100%;
				max-height: 323px;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 10px;
		padding-bottom: 26px;
		padding-left: $columnSizeMobile;
		padding-right: $columnSizeMobile;

		.grid {

			flex-direction: column;

			.item {

				&:not(:last-child) {

					margin-bottom: 14px;
				}

				img {

					min-height: auto;
					height: 14.2 * $columnSizeMobile;
				}
			}
		}
	}
}