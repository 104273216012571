.content-block {

	padding-left: 8 * $columnSizeDesktop;
	padding-right: 8 * $columnSizeDesktop;
	padding-top: 10px;
	padding-bottom: 30px;

	@media(max-width: $limit_mobile) {

		padding-left: 2 * $columnSizeMobile;
		padding-right: 2 * $columnSizeMobile;
		padding-bottom: 30px;
	}
}