.content-and-image-side-by-side {

	padding-top: 85px;
	padding-bottom: 135px;
	background-color: var(--bg-medium);

	h2 {

		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: 65px;
		margin-bottom: 55px;
	}

	.item {

		display: flex;

		.text-content {

			width: 50%;
			padding-left: $columnSizeDesktop;
			padding-right: 3 * $columnSizeDesktop;

			.title {

				font-size: 30px;
				font-style: normal;
				font-weight: 600;
				line-height: 35px;
				letter-spacing: 0.02em;
				margin-bottom: 31px;
				color: var(--medium-red-color);
			}

			.description {

				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.02em;
				color: var(--text-medium);
			}
		}

		.image-box {

			width: 50%;
			padding-left: $columnSizeDesktop;
			padding-right: $columnSizeDesktop;

			img {

				object-fit: cover;
				height: 11.2 * $columnSizeDesktop;
				width: 100%;
				border-radius: 10px;
			}
		}

		.image-box-mobile {
			display: none;
		}

		&:nth-child(2n) {

			.text-content {

				order: 1;
			}
		}

		&:not(:last-child) {

			margin-bottom: 123px;
		}
	}

	@media(max-width: $limit_mobile) {

		padding-top: 60px;
		padding-bottom: 94px;

		h2 {

			font-size: 30px;
			line-height: 35px;
			margin-bottom: 32px;
		}

		.item {
			flex-direction: column;
			.text-content {

				padding-left: 0;
				padding-right: 0;
				width: 100%;

				.title {

					font-size: 22px;
					line-height: 35px;
					margin-bottom: 11px;
				}

				.description {

					color: var(--black-color);
					font-size: 14px;
					line-height: 21px;
				}

				.image-box-mobile {
					display: block;
					img{
						width: 100%;
						border-radius: 10px;
						height: 11.2 * $columnSizeMobile;
						object-fit: cover;
					}
				}

			}

			.image-box {

				display: none;
			}

			&:not(:last-child) {

				margin-bottom: 79px;
			}
		}
	}
}