.blog {

	.blog-search {

		background-color: var(--bg-medium);
		margin-left: 2 * $columnSizeDesktop;
		margin-right: 2 * $columnSizeDesktop;
		padding-left: 2 * $columnSizeDesktop;
		padding-right: 2 * $columnSizeDesktop;
		padding-top: 72px;
		padding-bottom: 49px;

		.input-group {

			display: flex;
			width: 20 * $columnSizeDesktop;
			min-width: 550px;
			border-bottom: 1px solid var(--black-color);

			input {

				border: none;
				background-color: transparent;
				flex: 1;
				padding-right: 15px;
				font-family: "Montserrat", sans-serif;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0em;
				text-align: left;


				&::placeholder {

					color: var(--black-color);
				}

				&:active,
				&:focus {

					outline: none;
				}
			}
		}
	}

	.filters {
		
		padding-top: 105px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 58px;
		z-index: 1;
		position: relative;
		margin-left: 3 * $columnSizeDesktop;
		margin-right: 3 * $columnSizeDesktop;

		.filter {

			width: 10.5 * $columnSizeDesktop;
			max-width: 260px;
			position: relative;

			&.filter-category {

				margin-right: 77px;

				.options {

					z-index: 4;
				}
			}

			&.filter-unit {
				
				.options {

					z-index: 3;
				}
			}

			&.filter-order {

				.options {

					z-index: 2;
				}
			}

			&.show {

				.filter-header {

					svg {

						transform: rotate(-180deg);
					}
				}
			}

			.filter-header {

				cursor: pointer;
				background-color: var(--bg-light);
				border-bottom: 1px solid var(--black-color);
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-top: 8px;
				padding-bottom: 8px;

				.title {

					font-family: "Montserrat", sans-serif;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					padding-right: 15px;
				}

				svg {

					transition: transform $timeToAnimate;
					width: 14px;
					margin-right: 4px;
					height: auto;

					path {

						stroke: var(--medium-red-color);
					}
				}
			}

			.options {

				position: absolute;
				width: 100%;
				top: 100%;
				left: 0;
				overflow: auto;
				max-height: 0;
				transition: max-height $timeToAnimate;
				background-color: var(--bg-medium);

				li {

					font-family: "Montserrat", sans-serif;
					font-size: 15px;
					font-style: normal;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					padding: 9px 19px;
					transition: color $timeToColor, opacity $timeToAnimate;
					cursor: pointer;
					opacity: 0;

					&:first-child {

						margin-top: 9px;
					}

					&:last-child {

						margin-bottom: 9px;
					}

					&:active {

						color: var(--medium-red-color);
					}
				}
			}
		}
	}

	.blog-posts {

		padding-bottom: 187px;
		margin-right: 2 * $columnSizeDesktop;
		margin-left: 2 * $columnSizeDesktop;
		position: relative;

		.posts {

			.posts-columns {

				.column-right {

					.post-item {

						&:last-child {

							margin-bottom: 110px;
						}
					}
				}
			}

			.not-found {

				margin-left: 3 * $columnSizeDesktop;
				margin-right: 3 * $columnSizeDesktop;
				font-family: "Montserrat", sans-serif;
				font-size: 25px;
				font-style: normal;
				font-weight: 500;
				line-height: 35px;
				letter-spacing: 0em;
				max-width: 600px;
				margin-top: 35px;
			}
		}

		.navigation {

			margin-top: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			.btn-icon {

				&.btn-previous {

					transform: scaleX(-1);
				}

				&:not(:last-child) {

					margin-right: 16px;
				}
			}

			& > * {

				&:not(:last-child) {

					margin-right: 20px;
				}
			}
		}
	}

	@media(min-width: $limit_mobile + 1px) {

		.blog-posts {
			
			.filters {

				.filter {

					.options {

						li {

							&:hover {

								color: var(--medium-red-color);
							}
						}
					}
				}	
			}
		}
	}

	@media(max-width: $limit_mobile) {

		.blog-search {

			margin-left: $columnSizeMobile;
			margin-right: $columnSizeMobile;
			margin-bottom: 20px;
			padding-top: 21px;
			padding-left: $columnSizeMobile;
			padding-right: $columnSizeMobile;
			padding-bottom: 23px;

			.input-group {

				width: 100%;
				min-width: auto;

				input {

					font-size: 12px;
					line-height: 18px;
				}

				.btn-search {

					padding: 0;
					width: 20px;
					height: 26px;

					svg {

						width: 15px;
					}
				}
			}
		}

		.blog-posts {

			padding-top: 44px;
			padding-bottom: 69px;
			margin-right: 0;
			margin-left: 0;

			.filters {

				flex-direction: column;
				margin-bottom: 58px;
				margin-left: 3 * $columnSizeDesktop;
				margin-right: 3 * $columnSizeDesktop;

				.d-flex {

					width: 100%;
					flex-direction: column;
				}

				.filter {

					width: 100%;
					max-width: 100%;

					&.filter-category {

						margin-right: 0;
						margin-bottom: 25px;
					}

					&.filter-unit {

						margin-bottom: 25px;
					}

					&.show {

						.filter-header {

							svg {

								transform: rotate(-180deg);
							}
						}
					}

					.filter-header {

						padding-top: 5px;
						padding-bottom: 5px;

						.title {

							padding-right: 10px;
							font-size: 12px;
						}

						svg {

							margin-right: 2px;
						}
					}

					.options {

						li {

							font-size: 14px;
							line-height: 17px;
						}
					}
				}
			}

			.filters {

				margin-right: 2 * $columnSizeMobile;
				margin-left: 2 * $columnSizeMobile;
			}

			.posts {

				.not-found {

					font-size: 18px;
					line-height: 24px;
					margin-top: 0;
					margin-right: 2 * $columnSizeMobile;
					margin-left: 2 * $columnSizeMobile;
				}
			}
		}
	}
}