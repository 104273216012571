.btn {

	position: relative;
	cursor: pointer;
	border-radius: 30px;
	padding: 14px 35px;
	max-width: 100%;
	display: block;
	width: max-content;
	border: 1.5px solid transparent;
	transition: background-color $timeToColor,
				border-color $timeToColor;

	span {

		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 18.29px;
		text-align: center;
		text-decoration: none;
		transition: color $timeToColor;
		z-index: 1;
		position: relative;
	}
	
	&:active,
	&:focus {

		outline: none;
	}

	&.btn-primary {

		background-color: var(--pink-color);

		span {

			color: var(--text-light);
		}

		&:before {

			position: absolute;
			content: '';
			top: -1.5px;
			left: -1.5px;
			height: calc(100% + 3px);
			width: calc(100% + 3px);
			border-radius: 30px;
			transition: opacity $timeToColor;
			background: var(--bg-gradient);
		}
			
		&:active {

			border-color: var(--medium-red-color);

			span {
			
				color: var(--medium-red-color);
			}
			
			&:before {

				opacity: 0;
			}
		}
	}

	&.btn-secondary {

		background-color: transparent;
		
		&.bg-dark {

			border-color: var(--text-light);

			span {

				color: var(--text-light);
			}
		}

		&.bg-light {

			border-color: var(--medium-red-color);

			span {

				color: var(--medium-red-color);
			}
		}


		&:active {

			border-color: var(--medium-red-color);
			background-color: var(--pink-color);

			span {
			
				color: var(--medium-red-color);
			}
		}
	}
}


.btn-icon {
	
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: var(--bg-light);
	border: 1px solid var(--bg-light);
	box-shadow: 10px 10px 20px rgba(29, 29, 27, 0.25);
	transition: border-color $timeToColor,
				background-color $timeToColor;

	&.reverse-x {

		transform: scaleX(-1);
	}

	&.btn-download {

		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
	}

	&.btn-search {

		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
		width: 34px;
		height: 34px;
		padding: 5px;

		svg {

			path {

				transition: stroke $timeToColor;
			}
		}
	}

	svg {

		position: relative;
		width: 23px;
		height: auto;
		max-height: 23px;
	}

	&.blocked {

		pointer-events: none;
		background-color: #EAEAEA;
		border-color: #EAEAEA;

		svg {

			path {

				stroke: #A3A097;
			}
		}
	}

	&:not(.blocked) {

		&:active {

			&.btn-search {
				
				svg {
				
					path {
				
						stroke: var(--dark-red-color);
					}
				}
			}

			&:not(.btn-search) {
		
				background-color: var(--pink-color);
				border-color: var(--medium-red-color);
			}
		}

		&.bg-gradient {

			position: relative;
			background-color: var(--pink-color);

			svg {

				z-index: 1;
				
				path {

					transition: stroke $timeToColor;
					stroke: var(--text-light);
				}
			}

			&:before {

				position: absolute;
				content: '';
				top: -1.5px;
				left: -1.5px;
				height: calc(100% + 3px);
				width: calc(100% + 3px);
				border-radius: 50%;
				transition: opacity $timeToColor;
				transform: rotate(-70deg);
				background: var(--bg-gradient);
			}
		
			&:active {

				border-color: var(--medium-red-color);

				svg {

					path {

						stroke: var(--medium-red-color);
					}
				}

				&:before {

					opacity: 0;
				}
			}
		}
	}
}

@media(min-width: $limit_mobile + 1px) {

	.btn {

		&:hover {

			&.btn-primary {

				border-color: var(--medium-red-color);

				span {
				
					color: var(--medium-red-color);
				}
				
				&:before {

					opacity: 0;
				}
			}

			&.btn-secondary {

				border-color: var(--medium-red-color);
				background-color: var(--pink-color);

				span {
				
					color: var(--medium-red-color);
				}
			}
		}
	}

	.btn-icon {
		
		&:not(.blocked) {

			&:hover {

				&.btn-search {

					svg {

						path {

							stroke: var(--dark-red-color);
						}
					}
				}

				&:not(.btn-search) {
				
					background-color: var(--pink-color);
					border-color: var(--medium-red-color);
				}
				
				&.bg-gradient {
			
					svg {

						path {

							stroke: var(--medium-red-color);
						}
					}

					&:before {

						opacity: 0;
					}
				}
			}	
		}	
	}
}

@media(max-width: $limit_mobile) {

	.btn {

		span {

			font-size: 12px;
			font-weight: 600;
			line-height: 15px;
		}
	}
}